<template>
    <div id="createUniverse">
        <div class="create-universe">
            <mds-layout-grid class="create-universe">
                <mds-row>
                    <!-- <span>Universe > </span> -->
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails('/settings/universes'),
                            }"
                        >
                            Universe
                        </router-link>
                    </span>
                    <span class="create-universe-router">
                        > Create Universe</span
                    >
                </mds-row>
                <mds-row class="create-universe-heading">
                    Create Universe
                </mds-row>

                <mds-row class="create-universe-selection-section">
                    <!-- Create Universe Input -->
                    <mds-col :cols="4" class="create-universe-inputfield">
                        <mds-form>
                            <mds-input
                                label="Universe Name"
                                placeholder="Enter a name"
                                maxlength="250"
                                v-model.trim="universename"
                                :disabled="!isCreateModeOn"
                            ></mds-input>
                        </mds-form>
                    </mds-col>

                    <!-- Create Universe Type -->
                    <mds-col class="create-universe-type">
                        <mds-form>
                            <mds-fieldset
                                variation="radio-group"
                                legend="Universe Type"
                            >
                                <!-- Dynamic and Static Radio Button -->
                                <mds-radio-button
                                    class="radioButtonGroup"
                                    name="group"
                                    v-for="universeTypes in getUniverseType"
                                    :key="universeTypes.id"
                                    v-model="switchUniverseType"
                                    :value="universeTypes.id"
                                    :label="universeTypes.lable"
                                    :microcopy="universeTypes.text"
                                    @input="switchUniverseTypes(universeTypes)"
                                ></mds-radio-button>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                </mds-row>

                <mds-row class="create-universe-border"></mds-row>
            </mds-layout-grid>

            <!-- Dynamic And Static Universe Type -->
            <div class="middle-section" >
                <!-- Dynamic Universe Type -->
                <div v-if="this.universeType == 'Dynamic'">
                    <mds-layout-grid>
                        <mds-col :cols="12" class="dynamic-universe-heading"
                            >Universe Criteria</mds-col
                        >

                        <!-- Entity-Level Section -->

                        <mds-section
                            collapsible
                            :expanded="isEntityLevelSectionExpanded"
                            :key="dynamicUniverseEntityLevelSectionUpdate"
                            container
                            bold
                            :size="7"
                        >
                            <!-- Entity-Level Title Section -->
                            <template
                                v-slot:mds-section-title
                                class="dynamic-section-title"
                            >
                                <span id="title-id">Entity Level: </span>
                                <span class="dynamic-selected-value">{{
                                    currentEntryLevel
                                }}</span>
                            </template>

                            <!-- Add Entity-Level Button -->
                            <template
                                v-slot:mds-section-actions
                                v-if="addEntityLevel"
                            >
                                <mds-button
                                    variation="secondary"
                                    :disabled="!isselectedDynamicEntityLevel"
                                    size="small"
                                    @click="addDynamicEntityLevel()"
                                    >Add</mds-button
                                >
                            </template>

                            <!-- Radio-Button for selecting Entity-level -->
                            <mds-form>
                                <mds-fieldset variation="radio-group">
                                    <mds-radio-button
                                        name="group"
                                        v-for="entityLevel in getEntityTypes"
                                        :key="entityLevel.entitytypeid"
                                        v-model="selectedDynamicEntityLevel"
                                        :value="entityLevel.entitytypeid"
                                        :label="
                                            entityLevel.entitytypedescription
                                        "
                                        @input="
                                            switchDynamicEntityLevel(
                                                entityLevel
                                            )
                                        "
                                    ></mds-radio-button>
                                </mds-fieldset>
                            </mds-form>
                        </mds-section>

                        <!-- Add Criteria Selection Section -->

                        <!-- after adding it is used to show criteria with values -->

                        <span
                            v-if="isAddCriteria && AddCriteriaKey"
                            :key="dynamicUniverseCriteriaSectionUpdate"
                        >
                            <mds-section
                                class="dynamic-universe-criteria"
                                v-for="(
                                    addeddatapoint, index
                                ) in showAddedCriteria"
                                :key="index"
                                collapsible
                                :expanded="addeddatapoint.isSectionExpanded"
                                container
                                bold
                                :size="7"
                                @mds-section-expanded="
                                    expandedAddedCriteria(addeddatapoint)
                                "
                            >
                                <!-- Added Criteria Title Section -->
                                <template v-slot:mds-section-title>
                                    <span id="title-id">
                                        {{ addeddatapoint.Title }} :
                                    </span>
                                    <span
                                        class="dynamic-selected-value"
                                        v-for="(
                                            showselectedValue, index
                                        ) in addeddatapoint.Value"
                                        :key="index"
                                    >
                                        <span
                                            v-if="
                                                addeddatapoint.OperatorCondition !==
                                                'Between'
                                            "
                                            >{{ showselectedValue.text }}</span
                                        >
                                        <span
                                            v-if="
                                                addeddatapoint.OperatorCondition !==
                                                    'Between' &&
                                                index + 1 <
                                                    addeddatapoint.Value.length
                                            "
                                            >,
                                        </span>
                                        <span
                                            v-if="
                                                addeddatapoint.OperatorCondition ==
                                                'Between'
                                            "
                                        >
                                            {{ betweenDateFrom }} To
                                            {{ betweenDateTo }}
                                        </span>
                                    </span>
                                </template>

                                <!-- Remove Added Criteria Button -->
                                <template v-slot:mds-section-actions>
                                    <mds-button
                                        v-if="
                                            addeddatapoint.isSelectionValue &&
                                            !addeddatapoint.dynamicInputLevel
                                        "
                                        variation="secondary"
                                        size="small"
                                        :disabled="!isSelectedCriteria"
                                        @click="
                                            editAnotherCriteria(addeddatapoint)
                                        "
                                        >Add</mds-button
                                    >
                                    <mds-button
                                        v-if="
                                            addeddatapoint.isSelectionValue &&
                                            addeddatapoint.dynamicInputLevel
                                        "
                                        variation="secondary"
                                        size="small"
                                        :disabled="!isSelectedOperator"
                                        @click="
                                            editOperatorValue(addeddatapoint)
                                        "
                                        >Add</mds-button
                                    >

                                    <mds-button
                                        v-if="editCriteriaRemoveButton"
                                        class="dynamic-cancel-button"
                                        variation="icon-only"
                                        icon="remove"
                                        type="button"
                                        @click="
                                            removeAddedCriteria(
                                                addeddatapoint.Title
                                            )
                                        "
                                    >
                                        User
                                    </mds-button>
                                </template>

                                <mds-row>
                                    <!-- LeftBlock -->
                                    <mds-col :cols="6">
                                        <span
                                            class="dynamic-select-value-heading"
                                            v-if="
                                                addeddatapoint.isSelectionValue &&
                                                !addeddatapoint.dynamicInputLevel
                                            "
                                            >Select a Value</span
                                        >

                                        <!-- need to update -->
                                        <!-- <mds-search-field
                      v-if="
                        !addeddatapoint.isSelectionValue ||
                          !addeddatapoint.dynamicInputLevel
                      "
                      placeholder="Search..."
                      variation="secondary"
                      label="Search"
                      v-model="editSearchDynamicEntityName"
                      @keyup.enter="editSearchInput(addeddatapoint.isSelectionValue,addeddatapoint.getSelectionValue)"
                    ></mds-search-field> -->

                                        <!-- List of Values after Adding Criteria  -->

                                        <!-- ENUM API and dynamicDataTypeId = 7 -->
                                        <mds-form
                                            v-if="
                                                addeddatapoint.isSelectionValue &&
                                                !addeddatapoint.dynamicInputLevel
                                            "
                                            class="dynamic-list-group"
                                        >
                                            <span
                                                class="
                                                    dynamic-selection-of-value
                                                "
                                            >
                                                <mds-checkbox
                                                    class="
                                                        dynamic-selection-of-value-list
                                                    "
                                                    v-for="(
                                                        selectionValue, index
                                                    ) in addeddatapoint.getSelectionValue"
                                                    :checked="
                                                        selectionValue.selected
                                                    "
                                                    :key="index"
                                                    :v-model="isChecked"
                                                    :value="
                                                        selectionValue.value
                                                    "
                                                    :label="selectionValue.text"
                                                    @change="
                                                        editSelectedValue(
                                                            selectionValue
                                                        )
                                                    "
                                                ></mds-checkbox>
                                            </span>
                                        </mds-form>

                                        <span
                                            v-for="(
                                                addedInputLevel, index
                                            ) in addeddatapoint.Value"
                                            :key="index"
                                        >
                                            <mds-form
                                                v-if="
                                                    addeddatapoint.isSelectionValue &&
                                                    addeddatapoint.dynamicInputLevel
                                                "
                                                class="dynamic-input-field"
                                            >
                                                <!-- dynamicDataTypeId = 1 and dynamicDataTypeId = 2 -->
                                                <mds-select
                                                    placeholder="Select an operator"
                                                    v-if="
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            1 ||
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            2
                                                    "
                                                    label="Operator"
                                                    v-model="
                                                        addeddatapoint.OperatorCondition
                                                    "
                                                    @change="
                                                        editOperator($event)
                                                    "
                                                >
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in addeddatapoint.getSelectionValue"
                                                        :key="index"
                                                        :value="option.value"
                                                    >
                                                        {{ option.value }}
                                                    </option>
                                                </mds-select>

                                                <!-- dynamicDataTypeId = 3 and dynamicDataTypeId = 4 -->
                                                <mds-select
                                                    placeholder="Select an operator"
                                                    v-if="
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            3 ||
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            4
                                                    "
                                                    label="Operator"
                                                    v-model="
                                                        addeddatapoint.OperatorCondition
                                                    "
                                                    @change="
                                                        editOperator($event)
                                                    "
                                                >
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in addeddatapoint.getSelectionValue"
                                                        :key="index"
                                                        :value="option.value"
                                                    >
                                                        {{ option.value }}
                                                    </option>
                                                </mds-select>

                                                <!-- dynamicDataTypeId = 5 and dynamicDataTypeId = 6 -->

                                                <mds-select
                                                    placeholder="Select an operator"
                                                    v-if="
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            5 ||
                                                        addeddatapoint.dynamicDataTypeId ===
                                                            6
                                                    "
                                                    label="Operator"
                                                    v-model="
                                                        addeddatapoint.OperatorCondition
                                                    "
                                                    @change="
                                                        editOperator($event)
                                                    "
                                                >
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in addeddatapoint.getSelectionValue"
                                                        :key="index"
                                                        :value="option.value"
                                                    >
                                                        {{ option.value }}
                                                    </option>
                                                </mds-select>
                                            </mds-form>
                                        </span>
                                    </mds-col>

                                    <!-- RighBlock -->
                                    <mds-col :cols="6">
                                        <!-- List of Selected Value from left-side  -->

                                        <!-- ENUM API and dynamicDataTypeId = 7 -->
                                        <mds-list-group
                                            class="dynamic-select-value-heading"
                                            v-if="
                                                addeddatapoint.isSelectionValue &&
                                                !addeddatapoint.dynamicInputLevel
                                            "
                                            >Selected ({{
                                                addeddatapoint.selectedValueCount
                                            }})

                                            <mds-list-group-item
                                                class="dynamic-selected-list"
                                                v-for="(
                                                    value, index
                                                ) in addeddatapoint.getselectedValue"
                                                :key="index"
                                            >
                                                <span>{{ value.text }}</span>

                                                <!-- Remove single value from selected list -->
                                                <template
                                                    v-slot:mds-list-item-right
                                                >
                                                    <mds-button
                                                        class="
                                                            dynamic-remove-selected-value
                                                        "
                                                        icon="remove"
                                                        variation="icon-only"
                                                        size="small"
                                                        type="button"
                                                        @click="
                                                            editCancelSelectedValue(
                                                                value
                                                            )
                                                        "
                                                    ></mds-button>
                                                </template>
                                            </mds-list-group-item>
                                        </mds-list-group>

                                        <!-- DynamicInputLevel is true -->
                                        <span
                                            v-for="(
                                                addedInputLevel, index
                                            ) in addeddatapoint.Value"
                                            :key="index"
                                        >
                                            <mds-form
                                                v-if="
                                                    addeddatapoint.dynamicInputLevel ==
                                                    true
                                                "
                                            >
                                                <mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            1
                                                    "
                                                    label="Value"
                                                    type="number"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                ></mds-input>
                                                <mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            2
                                                    "
                                                    label="Value"
                                                    type="number"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                ></mds-input>

                                                <mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            3 &&
                                                        addeddatapoint.OperatorCondition !=
                                                            'Between'
                                                    "
                                                    label="Value"
                                                    type="date"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                ></mds-input>
                                                <span
                                                    v-if="
                                                        addeddatapoint.OperatorCondition ==
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        v-if="
                                                            addeddatapoint.isSelectionValue &&
                                                            addeddatapoint.dynamicDataTypeId ==
                                                                3
                                                        "
                                                        label="From"
                                                        type="date"
                                                        v-model.trim="
                                                            betweenFromInputValue
                                                        "
                                                        @select="
                                                            editInputValue(
                                                                this
                                                                    .betweenFromInputValue
                                                            )
                                                        "
                                                    ></mds-input>
                                                    <mds-input
                                                        v-if="
                                                            addeddatapoint.isSelectionValue &&
                                                            addeddatapoint.dynamicDataTypeId ==
                                                                3
                                                        "
                                                        label="To"
                                                        type="date"
                                                        v-model.trim="
                                                            betweenToInputValue
                                                        "
                                                        @select="
                                                            editInputValue(
                                                                this
                                                                    .betweenToInputValue
                                                            )
                                                        "
                                                    ></mds-input>
                                                </span>

                                                <mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            4 &&
                                                        addeddatapoint.OperatorCondition !==
                                                            'Between'
                                                    "
                                                    label="Value"
                                                    type="time"
                                                    step="2"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                ></mds-input>
                                                <span
                                                    v-if="
                                                        addeddatapoint.OperatorCondition ==
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        v-if="
                                                            addeddatapoint.isSelectionValue &&
                                                            addeddatapoint.dynamicDataTypeId ==
                                                                4
                                                        "
                                                        label="From"
                                                        type="time"
                                                        step="2"
                                                        v-model.trim="
                                                            betweenDateFrom
                                                        "
                                                        @select="
                                                            editInputValue(
                                                                this
                                                                    .betweenDateFrom
                                                            )
                                                        "
                                                    ></mds-input>
                                                    <mds-input
                                                        v-if="
                                                            addeddatapoint.isSelectionValue &&
                                                            addeddatapoint.dynamicDataTypeId ==
                                                                4
                                                        "
                                                        label="To"
                                                        type="time"
                                                        step="2"
                                                        v-model.trim="
                                                            betweenDateTo
                                                        "
                                                        @select="
                                                            editInputValue(
                                                                this
                                                                    .betweenDateTo
                                                            )
                                                        "
                                                    ></mds-input>
                                                </span>

                                                <mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            5
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                    label="Value"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                >
                                                </mds-input
                                                ><mds-input
                                                    v-if="
                                                        addeddatapoint.isSelectionValue &&
                                                        addeddatapoint.dynamicDataTypeId ==
                                                            6
                                                    "
                                                    @change="
                                                        editInputValue(
                                                            addedInputLevel.value
                                                        )
                                                    "
                                                    label="Value"
                                                    v-model.trim="
                                                        addedInputLevel.value
                                                    "
                                                ></mds-input>
                                            </mds-form>
                                        </span>
                                    </mds-col>
                                </mds-row>
                            </mds-section>
                        </span>

                        <!-- Add Criteria Selection Section -->
                        <mds-section
                            v-if="isAddCriteria"
                            class="dynamic-universe-criteria"
                            collapsible
                            expanded
                            container
                            bold
                            :size="7"
                        >
                            <!-- Add Criteria Title Section -->
                            <template v-slot:mds-section-title>
                                <span id="title-id"> {{ AddCriteria }} </span>
                            </template>

                            <!-- Add and Remove Criteria Button -->
                            <template v-slot:mds-section-actions>
                                <mds-button
                                    v-if="
                                        isSelectionValue && !dynamicInputLevel
                                    "
                                    variation="secondary"
                                    :disabled="!isChecked"
                                    size="small"
                                    @click="addAnotherCriteria()"
                                    >Add</mds-button
                                >
                                <mds-button
                                    v-if="isSelectionValue && dynamicInputLevel"
                                    variation="secondary"
                                    :disabled="!addDynamicOperatorButtonDisable"
                                    size="small"
                                    @click="addOperatorValue()"
                                    >Add</mds-button
                                >

                                <mds-button
                                    v-if="addCriteriaRemoveButton"
                                    class="dynamic-cancel-button"
                                    variation="icon-only"
                                    icon="remove"
                                    type="button"
                                    @click="closeSelectionValue()"
                                >
                                    Remove
                                </mds-button>
                            </template>

                            <!-- Select/Add Criteria from given list -->
                            <mds-row>
                                <!-- LeftBlock -->
                                <mds-col :cols="6">
                                    <span
                                        class="dynamic-select-value-heading"
                                        v-if="
                                            isSelectionValue &&
                                            !dynamicInputLevel
                                        "
                                        >Select a Value</span
                                    >

                                    <mds-search-field
                                        v-if="
                                            !isSelectionValue ||
                                            !dynamicInputLevel ||
                                            dynamicDataTypeZero
                                        "
                                        placeholder="Search..."
                                        variation="secondary"
                                        label="Search"
                                        v-model="searchDynamicEntityName"
                                        @mds-search-field-input-cleared="
                                            clearSearchInput()
                                        "
                                    ></mds-search-field>

                                    <!-- List of Criteria -->
                                    <mds-list-group
                                        class="dynamic-list-group"
                                        v-if="!isSelectionValue"
                                    >
                                        <mds-list-group-item
                                            class="dynamic-list-group-item"
                                            v-for="(
                                                addCriterias, incrementList
                                            ) in getDynamicSelectionList"
                                            :key="incrementList"
                                        >
                                            <span
                                                @click="
                                                    selectionofValue(
                                                        addCriterias
                                                    )
                                                "
                                                @mouseover="
                                                    descriptionOfDataPoint(
                                                        addCriterias.description,
                                                        addCriterias.name
                                                    )
                                                "
                                                >{{ addCriterias.name }}</span
                                            >
                                        </mds-list-group-item>
                                    </mds-list-group>

                                    <!-- list of criteria for dataType = 0 -->
                                    <mds-list-group
                                        class="dynamic-list-group"
                                        v-if="dynamicDataTypeZero"
                                    >
                                        <mds-list-group-item
                                            class="dynamic-list-group-item"
                                            v-for="(
                                                addCriterias, incrementList
                                            ) in dynamicDataTypeZeroSelectionList"
                                            :key="incrementList"
                                        >
                                            <span
                                                @click="
                                                    selectionofValue(
                                                        addCriterias
                                                    )
                                                "
                                                @mouseover="
                                                    descriptionOfDataPoint(
                                                        addCriterias.description,
                                                        addCriterias.name
                                                    )
                                                "
                                                >{{ addCriterias.name }}</span
                                            >
                                        </mds-list-group-item>
                                    </mds-list-group>

                                    <!-- List of Values after Adding Criteria  -->

                                    <!-- ENUM API and dynamicDataTypeId = 7 -->
                                    <mds-form
                                        v-if="
                                            isSelectionValue &&
                                            !dynamicInputLevel
                                        "
                                        class="dynamic-list-group"
                                    >
                                        <span
                                            class="dynamic-selection-of-value"
                                        >
                                            <mds-checkbox
                                                class="
                                                    dynamic-selection-of-value-list
                                                "
                                                v-for="(
                                                    selectionValue, index
                                                ) in getSelectionValue"
                                                :checked="
                                                    selectionValue.selected
                                                "
                                                :key="index"
                                                :v-model="isChecked"
                                                :value="selectionValue.value"
                                                :label="selectionValue.text"
                                                @change="
                                                    selectedValue(
                                                        selectionValue
                                                    )
                                                "
                                            ></mds-checkbox>
                                        </span>
                                    </mds-form>

                                    <mds-form
                                        v-if="
                                            isSelectionValue &&
                                            dynamicInputLevel
                                        "
                                        class="dynamic-input-field"
                                    >
                                        <!-- dynamicDataTypeId = 1 and dynamicDataTypeId = 2 -->
                                        <mds-select
                                            placeholder="Select an operator"
                                            v-if="
                                                this.dynamicDataTypeId === 1 ||
                                                this.dynamicDataTypeId === 2
                                            "
                                            label="Operator"
                                            v-model="selectedOperator"
                                        >
                                            <option
                                                v-for="(
                                                    option, index
                                                ) in getOptionsforValue"
                                                :key="index"
                                                :value="option.text"
                                            >
                                                {{ option.text }}
                                            </option></mds-select
                                        >

                                        <!-- dynamicDataTypeId = 3 and dynamicDataTypeId = 4 -->
                                        <mds-select
                                            placeholder="Select an operator"
                                            v-if="
                                                this.dynamicDataTypeId === 3 ||
                                                this.dynamicDataTypeId === 4
                                            "
                                            label="Operator"
                                            v-model="selectedOperator"
                                        >
                                            <option
                                                v-for="(
                                                    option, index
                                                ) in getOptionsforDate"
                                                :key="index"
                                                :value="option.text"
                                            >
                                                {{ option.text }}
                                            </option></mds-select
                                        >

                                        <!-- dynamicDataTypeId = 5 and dynamicDataTypeId = 6 -->

                                        <mds-select
                                            placeholder="Select an operator"
                                            v-if="
                                                this.dynamicDataTypeId === 5 ||
                                                this.dynamicDataTypeId === 6
                                            "
                                            label="Operator"
                                            v-model="selectedOperator"
                                        >
                                            <option
                                                v-for="(
                                                    option, index
                                                ) in getOptionsforSingleSelection"
                                                :key="index"
                                                :value="option.text"
                                            >
                                                {{ option.text }}
                                            </option></mds-select
                                        >
                                    </mds-form>
                                </mds-col>

                                <!-- RighBlock -->
                                <mds-col :cols="6">
                                    <!-- Discription of each Criteria -->
                                    <mds-row
                                        class="dynamic-data-point-heading"
                                        v-if="!isSelectionValue"
                                        >{{ dataPointHeading }}</mds-row
                                    >
                                    <mds-row
                                        class="dynamic-data-point-information"
                                        v-if="!isSelectionValue"
                                    >
                                        {{ dataPointDescription }}
                                    </mds-row>

                                    <!-- List of Selected Value from left-side  -->

                                    <!-- ENUM API and dynamicDataTypeId = 7 -->
                                    <mds-list-group
                                        class="dynamic-select-value-heading"
                                        v-if="
                                            isSelectionValue &&
                                            !dynamicInputLevel
                                        "
                                        >Selected ({{
                                            this.selectedValueCount
                                        }})

                                        <mds-list-group-item
                                            class="dynamic-selected-list"
                                            v-for="value in getselectedValue"
                                            :key="value.id"
                                        >
                                            <span>{{ value.text }}</span>

                                            <!-- Remove single value from selected list -->
                                            <template
                                                v-slot:mds-list-item-right
                                            >
                                                <mds-button
                                                    class="
                                                        dynamic-remove-selected-value
                                                    "
                                                    icon="remove"
                                                    variation="icon-only"
                                                    size="small"
                                                    type="button"
                                                    @click="
                                                        cancelSelectedValue(
                                                            value
                                                        )
                                                    "
                                                ></mds-button>
                                            </template>
                                        </mds-list-group-item>
                                    </mds-list-group>

                                    <!-- DynamicInputLevel is true -->
                                    <mds-form v-if="dynamicInputLevel == true">
                                        <mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 1
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            type="number"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input>
                                        <mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 2
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            type="number"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input>

                                        <mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 3 &&
                                                selectedOperator !== 'Between'
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            type="date"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input>
                                        <span
                                            v-if="selectedOperator == 'Between'"
                                        >
                                            <mds-input
                                                v-if="
                                                    isSelectionValue &&
                                                    this.dynamicDataTypeId == 3
                                                "
                                                placeholder="Enter a Value"
                                                label="From"
                                                type="date"
                                                v-model.trim="dynamicInputValue"
                                            ></mds-input>
                                            <mds-input
                                                v-if="
                                                    isSelectionValue &&
                                                    this.dynamicDataTypeId == 3
                                                "
                                                placeholder="Enter a Value"
                                                label="To"
                                                type="date"
                                                v-model.trim="
                                                    dynamicInputValueTo
                                                "
                                            ></mds-input>
                                        </span>

                                        <mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 4 &&
                                                selectedOperator !== 'Between'
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            type="time"
                                            step="2"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input>
                                        <span
                                            v-if="selectedOperator == 'Between'"
                                        >
                                            <mds-input
                                                v-if="
                                                    isSelectionValue &&
                                                    this.dynamicDataTypeId == 4
                                                "
                                                placeholder="Enter a Value"
                                                label="From"
                                                type="time"
                                                step="2"
                                                v-model.trim="dynamicInputValue"
                                            ></mds-input>
                                            <mds-input
                                                v-if="
                                                    isSelectionValue &&
                                                    this.dynamicDataTypeId == 4
                                                "
                                                placeholder="Enter a Value"
                                                label="To"
                                                type="time"
                                                step="2"
                                                v-model.trim="
                                                    dynamicInputValueTo
                                                "
                                            ></mds-input>
                                        </span>
                                        <mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 5
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input
                                        ><mds-input
                                            v-if="
                                                isSelectionValue &&
                                                this.dynamicDataTypeId == 6
                                            "
                                            placeholder="Enter a Value"
                                            label="Value"
                                            v-model.trim="dynamicInputValue"
                                        ></mds-input>
                                    </mds-form>
                                </mds-col>
                            </mds-row>
                        </mds-section>
                    </mds-layout-grid>
                </div>

                <!--start static section -->
                <div
                    class="middle-section-static"
                    v-if="this.universeType == 'Static'"
                >
                    <div class="middle-section-header-name">Entities</div>
                    <div class="staticTabs">
                        <mds-tabs
                            :content="tabsContent"
                            v-on:mds-tabs-item-active="setActiveItem"
                        ></mds-tabs>
                        <div
                            class="selectEntitiesTab"
                            v-if="selectEntitiesTabShow == true"
                        >
                            <mds-layout-grid>
                                <mds-row class="tabEntitiesRows">
                                    <mds-col
                                        :cols="4"
                                        style="
                                            overflow-y: scroll;
                                            overflow-x: hidden;
                                            height: 535px;
                                        "
                                    >
                                        <div class="searchEntitiesField">
                                            <mds-search-field
                                                class="search-input-component"
                                                v-model="searchField"
                                                placeholder="Search by name or identifier"
                                                @keyup.enter="
                                                    searchTextEntered()
                                                "
                                                @mds-search-field-input-cleared="
                                                    searchFieldCleared($event)
                                                "
                                            >
                                            </mds-search-field>
                                        </div>
                                        <div class="levelRadioButton">
                                            <mds-form>
                                                <mds-fieldset
                                                    legend="Entity Level"
                                                    variation="radio-group"
                                                >
                                                    <mds-radio-button
                                                        class="
                                                            radioButtonGroup1
                                                        "
                                                        name="group1"
                                                        v-for="(
                                                            entityLevel,
                                                            entityIndex
                                                        ) in getEntityTypes"
                                                        :key="entityIndex"
                                                        v-model="
                                                            changeStaticEntityLevel
                                                        "
                                                        :value="
                                                            entityLevel.entitytypeid
                                                        "
                                                        :label="
                                                            entityLevel.entitytypedescription
                                                        "
                                                        @input="
                                                            switchStaticEntityLevel(
                                                                entityLevel
                                                            )
                                                        "
                                                    ></mds-radio-button>
                                                </mds-fieldset>
                                            </mds-form>
                                        </div>
                                        <div
                                            style="
                                                border-top: 1px solid #cccccc;
                                                border-radius: 5px;
                                            "
                                        ></div>
                                        <div>
                                            <mds-layout-grid>
                                                <mds-row>
                                                    <mds-col
                                                        class="
                                                            additionalSearch-header-name
                                                        "
                                                        >Additional Search
                                                        Criteria</mds-col
                                                    >
                                                    <mds-col
                                                        class="
                                                            additionalSearchClearAll
                                                        "
                                                    >
                                                        <mds-button
                                                            variation="flat"
                                                            v-on:click="
                                                                additionalSearchClearAll()
                                                            "
                                                            >Clear
                                                            All</mds-button
                                                        >
                                                    </mds-col>
                                                </mds-row>
                                            </mds-layout-grid>
                                        </div>
                                        <!-- additional search criteria v-for -->
                                        <div
                                            class="additionalSearch"
                                            v-for="(
                                                item, index
                                            ) in duplicateAdditionalSearch"
                                            :key="index"
                                        >
                                            <mds-form>
                                                <mds-layout-grid>
                                                    <mds-row>
                                                        <mds-col
                                                            style="
                                                                text-align: right;
                                                            "
                                                        >
                                                            <mds-button
                                                                variation="icon-only"
                                                                v-on:click="
                                                                    additionalSearchArrayClose(
                                                                        index
                                                                    )
                                                                "
                                                                icon="remove"
                                                                type="button"
                                                                >Remove</mds-button
                                                            >
                                                        </mds-col>
                                                    </mds-row>
                                                </mds-layout-grid>
                                                <mds-select
                                                    label="Data Point"
                                                    style="margin-top: -25px"
                                                    disabled
                                                >
                                                    <option value="">
                                                        {{ item.dataPoint }}
                                                    </option>
                                                </mds-select>
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Operator"
                                                    v-model.trim="item.operator"
                                                    disabled
                                                ></mds-input>

                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Value"
                                                    v-model.trim="item.value"
                                                    disabled
                                                ></mds-input>
                                                <div
                                                    style="
                                                        margin-top: -8px;
                                                        text-align: right;
                                                    "
                                                >
                                                    <mds-button
                                                        variation="secondary"
                                                        disabled
                                                        >Apply
                                                    </mds-button>
                                                </div>
                                            </mds-form>
                                        </div>
                                        <!-- duplicate additional search criteria -->
                                        <div class="additionalSearch">
                                            <mds-form>
                                                <mds-layout-grid>
                                                    <mds-row>
                                                        <mds-col
                                                            style="
                                                                text-align: right;
                                                            "
                                                        >
                                                            <mds-button
                                                                variation="icon-only"
                                                                v-on:click="
                                                                    additionalSearchClose()
                                                                "
                                                                icon="remove"
                                                                type="button"
                                                                >Remove</mds-button
                                                            >
                                                        </mds-col>
                                                    </mds-row>
                                                </mds-layout-grid>
                                                <mds-select
                                                    label="Data Point"
                                                    v-model="
                                                        additionalsearchdatapoint
                                                    "
                                                    @change="
                                                        changeAdditionalSearchDataPoint(
                                                            $event
                                                        )
                                                    "
                                                    style="margin-top: -25px"
                                                >
                                                    <option value="">
                                                        Select data point
                                                    </option>
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in additionalSearchDataPoints"
                                                        :key="index"
                                                        :value="option.id"
                                                    >
                                                        {{ option.name }}
                                                    </option>
                                                </mds-select>
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Operator"
                                                    v-if="
                                                        additionalSearchOperators ==
                                                            undefined ||
                                                        additionalSearchOperators.length ==
                                                            0
                                                    "
                                                    v-model.trim="
                                                        additionalsearchoperator
                                                    "
                                                    :disabled="IsAdditionalSearchDefaultOperator"
                                                    placeholder="Enter a operator"
                                                ></mds-input>
                                                <mds-select
                                                    label="Operator"
                                                    v-model="
                                                        additionalsearchoperator
                                                    "
                                                    style="margin-top: -8px"
                                                    v-if="
                                                        additionalSearchOperators.length !=
                                                        0
                                                    "
                                                >
                                                    <option value="">
                                                        Select an operator
                                                    </option>
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in additionalSearchOperators"
                                                        :key="index"
                                                        :value="option.text"
                                                    >
                                                        {{ option.text }}
                                                    </option>
                                                </mds-select>
                                                <!--Static Additinal Search Value -->
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Value"
                                                    v-if="addSearchDataTypeId == null"
                                                    v-model.trim="
                                                        additionalsearchvalue
                                                    "
                                                    placeholder="Enter a value"
                                                ></mds-input>
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Value"
                                                    type="number"
                                                    v-if="
                                                        addSearchDataTypeId ==
                                                            1 &&
                                                        additionalSearchValues.length ==
                                                            0
                                                    "
                                                    v-model.trim="
                                                        additionalsearchvalue
                                                    "
                                                    placeholder="Enter a value"
                                                ></mds-input>
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Value"
                                                    type="number"
                                                    v-if="
                                                        addSearchDataTypeId ==
                                                            2 &&
                                                        additionalSearchValues.length ==
                                                            0
                                                    "
                                                    v-model.trim="
                                                        additionalsearchvalue
                                                    "
                                                    placeholder="Enter a value"
                                                ></mds-input>
                                                <div
                                                    style="margin-bottom: 20px"
                                                    v-if="
                                                        additionalsearchoperator !=
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="Value"
                                                        type="date"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                3 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalue
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                </div>
                                                <div
                                                    style="margin-bottom: 20px"
                                                    v-if="
                                                        additionalsearchoperator ==
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="From"
                                                        type="date"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                3 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalueFrom
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="To"
                                                        type="date"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                3 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalueTo
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                </div>
                                                <div
                                                    style="margin-bottom: 20px"
                                                    v-if="
                                                        additionalsearchoperator !=
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="Value"
                                                        type="time"
                                                        step="2"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                4 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalue
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                </div>
                                                <div
                                                    style="margin-bottom: 20px"
                                                    v-if="
                                                        additionalsearchoperator ==
                                                        'Between'
                                                    "
                                                >
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="From"
                                                        type="time"
                                                        step="2"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                4 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalueFrom
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                    <mds-input
                                                        style="margin-top: -8px"
                                                        label="To"
                                                        type="time"
                                                        step="2"
                                                        v-if="
                                                            addSearchDataTypeId ==
                                                                4 &&
                                                            additionalSearchValues.length ==
                                                                0
                                                        "
                                                        v-model.trim="
                                                            additionalsearchvalueTo
                                                        "
                                                        placeholder="Enter a value"
                                                    ></mds-input>
                                                </div>
                                                <mds-input
                                                    style="margin-top: -8px"
                                                    label="Value"
                                                    v-if="
                                                        (addSearchDataTypeId ==
                                                            5 ||
                                                            addSearchDataTypeId ==
                                                                6) &&
                                                        additionalSearchValues.length ==
                                                            0
                                                    "
                                                    v-model.trim="
                                                        additionalsearchvalue
                                                    "
                                                    placeholder="Enter a value"
                                                ></mds-input>
                                                <mds-select
                                                    label="Value"
                                                    @change="
                                                        changeAdditionalSearchValue(
                                                            $event
                                                        )
                                                    "
                                                    v-model="
                                                        additionalsearchvalue
                                                    "
                                                    style="margin-top: -8px"
                                                    v-if="
                                                        additionalSearchValues.length !=
                                                        0
                                                    "
                                                >
                                                    <option value="">
                                                        Select Value
                                                    </option>
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in additionalSearchValues"
                                                        :key="index"
                                                        :value="option.value"
                                                    >
                                                        {{ option.text }}
                                                    </option>
                                                </mds-select>
                                                <div
                                                    style="
                                                        margin-top: -8px;
                                                        text-align: right;
                                                    "
                                                >
                                                    <button-component
                                                        :onClick="onApplyClick"
                                                        buttonName="Apply"
                                                        buttonVariation="secondary"
                                                        :isDisabled="
                                                            !additionalsearchButtonDisable
                                                        "
                                                    >
                                                    </button-component>
                                                </div>
                                            </mds-form>
                                        </div>
                                    </mds-col>

                                    <mds-col :cols="4">
                                        <div
                                            style="
                                                overflow-y: auto;
                                                height: 495px;
                                            "
                                        >
                                            <mds-table multiselection row-hover>
                                                <mds-thead
                                                    @mds-thead-multiselection-changed="
                                                        handleMultiSelectionEntityName(
                                                            $event
                                                        )
                                                    "
                                                    :indeterminate="
                                                        selectionStatus.isIndeterminate
                                                    "
                                                    :checked="
                                                        selectionStatus.isAllSelected
                                                    "
                                                >
                                                    <mds-th
                                                        v-for="(
                                                            header, index
                                                        ) in entityNameHeader"
                                                        :key="index"
                                                    >
                                                        {{ header.text }}
                                                    </mds-th>
                                                </mds-thead>
                                                <mds-tbody>
                                                    <mds-tr
                                                        v-for="(
                                                            row, index
                                                        ) in entityNameRow"
                                                        :key="row.entityid"
                                                        :checked="
                                                            row.checked || false
                                                        "
                                                        :multiselection-label="
                                                            row.name
                                                        "
                                                        @mds-tr-multiselection-changed="
                                                            handleRowSelectEntityNameEvent(
                                                                index,
                                                                $event,
                                                                row
                                                            )
                                                        "
                                                    >
                                                        <mds-td
                                                            v-for="(
                                                                header, i
                                                            ) in entityNameHeader"
                                                            :key="i"
                                                            >{{
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            }}
                                                        </mds-td>
                                                    </mds-tr>
                                                </mds-tbody>
                                            </mds-table>
                                            <mds-empty-state
                                            v-if="entityNameRow.length ==
                                                    0 ||
                                                entityNameRow ==
                                                    undefined"
                                            class="no-results-message"
                                            size="small"
                                            title="No results matched"
                                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                                        ></mds-empty-state>
                                        </div>
                                        <mds-pagination
                                            :key="paginationComponentKey"
                                            v-if="entityNameRowLength > 0"
                                            icon-only
                                            :total-items="totalItems"
                                            :pageSize="pageSize"
                                            :pageSizes="[20]"
                                            @mds-pagination-page-changed="
                                                paginateTable
                                            "
                                            style="margin-top: 10px"
                                        ></mds-pagination>
                                        
                                    </mds-col>

                                    <mds-col
                                        :cols="4"
                                        style="
                                            overflow-y: scroll;
                                            height: 535px;
                                        "
                                    >
                                        <mds-table row-hover>
                                            <mds-thead>
                                                <mds-th
                                                    v-for="(
                                                        header, index
                                                    ) in selectedEntityNameHeader"
                                                    :key="index"
                                                    :width="header.width"
                                                >
                                                    {{ header.text }}
                                                </mds-th>
                                            </mds-thead>
                                            <mds-tbody>
                                                <mds-tr
                                                    v-for="(
                                                        row, index
                                                    ) in selectedEntityNameRow"
                                                    :key="index"
                                                    :checked="row.checked"
                                                    :multiselection-label="
                                                        row.name
                                                    "
                                                >
                                                    <mds-td
                                                        v-for="(
                                                            header, i
                                                        ) in selectedEntityNameHeader"
                                                        :key="i"
                                                    >
                                                        {{
                                                            row[
                                                                header.fieldName
                                                            ]
                                                        }}
                                                        <template
                                                            v-if="
                                                                header.fieldName ===
                                                                'actions'
                                                            "
                                                        >
                                                            <mds-button
                                                                class="
                                                                    actionButton
                                                                "
                                                                icon="trash"
                                                                variation="icon-only"
                                                                v-on:click="
                                                                    deleteEntityStatic(
                                                                        row
                                                                    )
                                                                "
                                                                size="small"
                                                                type="button"
                                                            ></mds-button>
                                                        </template>
                                                    </mds-td>
                                                </mds-tr>
                                            </mds-tbody>
                                        </mds-table>
                                        <mds-empty-state
                                            v-if="
                                                selectedEntityNameRow.length ==
                                                    0 ||
                                                selectedEntityNameRow ==
                                                    undefined
                                            "
                                            class="no-results-message"
                                            size="small"
                                            title="No entities selected"
                                            message="Filter and select entities from the list to the left to create your universe."
                                        ></mds-empty-state>
                                    </mds-col>
                                </mds-row>
                            </mds-layout-grid>
                        </div>
                        <div
                            class="uploadEntitiesTab"
                            v-if="uploadEntitiesTabShow == true"
                        >
                            <mds-layout-grid>
                                <mds-row class="tabEntitiesRows">
                                    <mds-col :cols="4">
                                        <mds-form>
                                            <mds-select
                                                placeholder="Select..."
                                                label="Identifier"
                                                v-model="identifier"
                                                microcopy-above="Select the identifier used in your file."
                                                microcopy-below="Please upload a CSV or Excel file with each entity on a separate line."
                                                @change="
                                                    changeIdentifier($event)
                                                "
                                            >
                                                <option
                                                    v-for="item in getIdentifierData"
                                                    :key="item.value"
                                                    :value="item.text"
                                                >
                                                    {{ item.text }}
                                                </option>
                                            </mds-select>
                                        </mds-form>
                                    </mds-col>

                                    <mds-col :cols="4">
                                        <div class="uploadEntitySection">
                                            <div>
                                                <label class="labelImportFile"
                                                    >Import File</label
                                                >
                                            </div>
                                            <div
                                                class="uploadEntity"
                                                v-if="uploadingFiles"
                                            >
                                                <input
                                                    class="
                                                        file-upload-container
                                                    "
                                                    type="file"
                                                    ref="fileUploadContainer"
                                                    accept=".xlsx, .xls, .csv"
                                                    hidden
                                                />
                                                <mds-button
                                                    variation="secondary"
                                                    v-on:click="
                                                        uploadEntityFile()
                                                    "
                                                    :disabled="
                                                        !uploadButtonDisable
                                                    "
                                                    icon="upload"
                                                    type="button"
                                                    >Upload</mds-button
                                                >
                                                <p class="notesText">
                                                    Please note: Larger files
                                                    may take more time to
                                                    process
                                                </p>
                                            </div>
                                            <div
                                                class="uploadEntity"
                                                v-if="uploadingFileSpinner"
                                            >
                                                <div style="display: flex">
                                                    <span>
                                                        <mds-loader
                                                            size="small"
                                                            aria-label="Small Loader"
                                                        ></mds-loader>
                                                    </span>
                                                    <span>
                                                        <p
                                                            style="
                                                                margin-top: 13px;
                                                                margin-left: 12px;
                                                                color: #000000;
                                                                font-style: normal;
                                                                font-weight: normal;
                                                                font-size: 16px;
                                                                line-height: 21px;
                                                            "
                                                        >
                                                            File uploading...
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                class="uploadEntity"
                                                v-if="uploadEntityGetResponse"
                                            >
                                                <mds-layout-grid>
                                                    <mds-row>
                                                        <mds-col
                                                            class="
                                                                uploadEntityFile
                                                            "
                                                            >{{
                                                                uploadEntityFileName
                                                            }}</mds-col
                                                        >
                                                        <mds-col
                                                            class="
                                                                uploadEntityCloseBtn
                                                            "
                                                        >
                                                            <mds-button
                                                                variation="icon-only"
                                                                v-on:click="
                                                                    uploadEntityClose()
                                                                "
                                                                icon="remove"
                                                                type="button"
                                                                >Remove</mds-button
                                                            >
                                                        </mds-col>
                                                    </mds-row>
                                                </mds-layout-grid>
                                                <div
                                                    class="uploadEntityMatched"
                                                >
                                                    Entities uploaded:
                                                    {{ uploadEntityTotal }}
                                                </div>
                                                <div
                                                    class="uploadEntityMatched"
                                                >
                                                    Entities matched:
                                                    {{ uploadEntityMatched }}
                                                </div>
                                                <div
                                                    class="uploadEntityMatched"
                                                    v-if="
                                                        uploadEntityUnmatched !=
                                                        0
                                                    "
                                                >
                                                    Entities unmatched:
                                                    {{ uploadEntityUnmatched }}
                                                </div>
                                            </div>
                                        </div>
                                    </mds-col>

                                    <mds-col
                                        :cols="4"
                                        style="
                                            overflow-y: scroll;
                                            height: 535px;
                                        "
                                    >
                                        <div style="margin-top: 25px">
                                            <div v-if="uploadEntityWarning">
                                                <mds-alert
                                                    variation="warning"
                                                    title="We were unable to match every item in your file."
                                                    :list="uploadEntityList"
                                                    persistent
                                                >
                                                    You can continue with your
                                                    unmatched items or you can
                                                    download the file and check
                                                    the following criteria
                                                    before uploading again:
                                                    <mds-button
                                                        slot="mds-alert-custom-action"
                                                        v-on:click="
                                                            downloadUploadEntity()
                                                        "
                                                        >Download & Check
                                                        File</mds-button
                                                    >
                                                </mds-alert>
                                            </div>
                                            <div v-if="uploadEntityError">
                                                <mds-alert
                                                    variation="error"
                                                    title="We were unable to match any item in your file."
                                                    :list="uploadEntityList"
                                                    persistent
                                                >
                                                    No entities were matched,
                                                    please download the file and
                                                    check the following criteria
                                                    before uploading again:
                                                    <mds-button
                                                        slot="mds-alert-custom-action"
                                                        v-on:click="
                                                            downloadUploadEntity()
                                                        "
                                                        >Download & Check
                                                        File</mds-button
                                                    >
                                                </mds-alert>
                                            </div>
                                            <div v-if="uploadEntitySuccess">
                                                <mds-alert
                                                    title="Success!"
                                                    variation="success"
                                                    persistent
                                                >
                                                    Your file was successfully
                                                    uploaded. You can download
                                                    the file to review your
                                                    matched items or continue
                                                    and save your universe.
                                                </mds-alert>
                                            </div>
                                        </div>
                                    </mds-col>
                                </mds-row>
                            </mds-layout-grid>
                        </div>
                    </div>
                </div>
                <!--end static section -->
            </div>

            <!-- Footer Section -->
            <div class="footerSection">
                <mds-layout-grid>
                    <mds-row>
                        <mds-col>
                            <div style="border-top: 1px solid #cccccc"></div>
                        </mds-col>
                    </mds-row>
                    <mds-row class="bottom-section-button">
                        <mds-col>
                            <button-component
                                :onClick="cancelButton"
                                buttonName="Cancel"
                                buttonVariation="secondary"
                            >
                            </button-component>
                        </mds-col>
                        <mds-col style="text-align: right">
                            <span>
                                <button-component
                                    class="bottom-section-save-button"
                                    :onClick="saveButton"
                                    buttonName="Save"
                                    buttonVariation="primary"
                                    :isDisabled="!saveButtonDisable"
                                >
                                </button-component>
                            </span>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
            </div>
        </div>
        <!-- changing universe from dynamic to static or vice-versa -->
        <mds-dialog
            v-model="toggleUniverseRadioButton"
            action-required
            title="Are you sure you want to change the type of universe you are creating?"
        >
            If you change the universe type, any work you’ve done already will
            be lost.
            <template v-slot:mds-dialog-actions-right>
                <mds-button-container right-aligned>
                    <mds-button
                        type="button"
                        variation="secondary"
                        @click="
                            toggleUniverseRadioButton =
                                !toggleUniverseRadioButton
                        "
                        v-on:click="noSwitchUniverse()"
                    >
                        No
                    </mds-button>
                    <mds-button
                        type="button"
                        variation="primary"
                        @click="
                            toggleUniverseRadioButton =
                                !toggleUniverseRadioButton
                        "
                        v-on:click="yesSwitchUniverse()"
                    >
                        Yes
                    </mds-button>
                </mds-button-container>
            </template>
        </mds-dialog>

        <!-- changing Entity-Level in Dynamic type -->
        <mds-dialog
            v-model="DynamicEntityLevelChange"
            action-required
            title="Are you sure you want to change the entity level?"
        >
            If you change the entity level, any criteria you’ve configured will
            be reset.
            <template v-slot:mds-dialog-actions-right>
                <mds-button-container right-aligned>
                    <mds-button
                        type="button"
                        variation="secondary"
                        v-on:click="noSwitchDynamicEntityLevel()"
                    >
                        No
                    </mds-button>
                    <mds-button
                        type="button"
                        variation="primary"
                        v-on:click="yesSwitchDynamicEntityLevel()"
                    >
                        Yes
                    </mds-button>
                </mds-button-container>
            </template>
        </mds-dialog>
        <loader-component v-if="showLoader"></loader-component>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";
import MdsRadioButton from "@mds/radio-button";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsTabs from "@mds/tabs";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import MdsDialog from "@mds/dialog";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import MdsSearchField from "@mds/search-field";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import MdsAlert from "@mds/alert";
import XLSX from "xlsx";
import {
    getUploadEntity,
    additionalSearchApply,
    getEntityListData,
} from "../../services/reporting_service";
import MdsEmptyState from "@mds/empty-state";
import MdsPagination from "@mds/pagination";
// -------------
import MdsSection from "@mds/section";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsCheckbox from "@mds/checkbox";
import { mapActions, mapGetters } from "vuex";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsSelect from "@mds/select";
import MdsLoader from "@mds/loader";
import {
    getSelectForMultipleEnum,
    getSelectForReferenceTypeId,
} from "../../services/add_investment_service";
import { universeMixins } from "../../mixins/universeMixins.js";
import { dateFormatterMixin } from "../../mixins/date.js";

export default {
    name: "createuniverse",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsForm,
        MdsInput,
        MdsFieldset,
        MdsRadioButton,
        MdsButton,
        MdsButtonContainer,
        MdsTabs,
        MdsSection,
        MdsListGroup,
        MdsListGroupItem,
        MdsDialog,
        ButtonComponent,
        MdsCheckbox,
        LoaderComponent,
        MdsSelect,
        MdsSearchField,
        NotificationComponent,
        MdsLoader,
        MdsAlert,
        MdsEmptyState,
        MdsPagination,
    },
    mixins: [universeMixins,dateFormatterMixin],

    async mounted() {
        this.editUniverse = {
            type: await this.$route.query.type,
            listid: await this.$route.query.listid,
            listdescription: await this.$route.query.name,
            ispublic: await this.$route.query.ispublic,
            iseditable : await this.$route.query.iseditable
        };

        this.createUniverse = this.$route.params.createUniverseData;
        this.getEntityResponseData();
        //await this.fetchEntityList(1);
        await this.fetchDynamicDataPoint(1);
        this.changeStaticEntityLevel = 1;
        await this.editCheckType();
        await this.fetchEntityTypes();
        this.additionalSearchDataPoints = this.getDynamicDataPoint;
        this.showLoader = false;
    },

    watch: {
        dynamicInputValue(val) {
            if (val != "") {
                this.isChecked = true;
            } else {
                this.isChecked = false;
            }
        },
        searchDynamicEntityName(){
            this.searchInput(this.searchDynamicEntityName);
        },
        selectedEntityNameRow(val){
            if(val.length != 0){
                this.selectedMatchUploadEntity.length = 0;
            }       
        },
        selectedMatchUploadEntity(val){
            if(val.length != 0){
                this.selectedEntityNameRow.length = 0;
                this.selectedEntityName.clear();
            }             
        }
    },

    computed: {
        ...mapGetters("bulkEdit", ["getEntities"]),
        ...mapGetters("entity", ["getEntityTypes"]),
        ...mapGetters("reporting", [
            "getDynamicDataPoint",
            "getSaveUniverse",
            "getEntity",
            "getEditUniverseList",
        ]),

        entityNameRowLength() {
            if (this.entityNameRow !== undefined) {
                if (this.entityNameRow.length > 0) {
                    return this.entityNameRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        additionalsearchButtonDisable() {
            if(this.additionalsearchoperator != 'Between'){
                return (
                    this.additionalsearchoperator &&
                    this.additionalsearchdatapoint &&
                    this.additionalsearchvalue
                );               
            }else{
                 return (
                    this.additionalsearchoperator &&
                    this.additionalsearchdatapoint &&
                    this.additionalsearchvalueFrom && 
                    this.additionalsearchvalueTo
                );
            }          
        },

        saveButtonDisable() {
            if (this.universeType == "Dynamic") {
                if (this.showAddedCriteria.length == 0) {
                    return;
                } else {
                    return this.showAddedCriteria, this.universename;
                }
            } else {
                if (
                    this.selectedEntityNameRow.length == 0 &&
                    this.selectedMatchUploadEntity.length == 0
                ) {
                    return;
                } else {
                    return (
                        (this.universename && this.selectedEntityNameRow) ||
                        (this.universename && this.selectedMatchUploadEntity)
                    );
                }
            }
        },

        uploadButtonDisable() {
            return this.identifier;
        },

        selectionStatus() {
            const filtered = this.entityNameRow.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.entityNameRow.length,
                isAllSelected:
                    filtered.length === this.entityNameRow.length &&
                    filtered.length != 0 &&
                    this.entityNameRow != 0,
            };
        },

        uploadEntityLength() {
            if (this.selectedMatchUploadEntity !== undefined) {
                if (this.selectedMatchUploadEntity.length > 0) {
                    return this.selectedMatchUploadEntity.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        addDynamicOperatorButtonDisable() {
            if (this.selectedOperator == "Between") {
                return (
                    this.selectedOperator &&
                    this.dynamicInputValue &&
                    this.dynamicInputValueTo
                );
            } else {
                return this.dynamicInputValue && this.selectedOperator;
            }
        },
    },

    methods: {
        ...mapActions("bulkEdit", ["getEntityList"]),
        ...mapActions("entity", [
            "fetchEntityTypes",
            "fetchDropdownListEntity",
        ]),
        ...mapActions("reporting", [
            "fetchDynamicDataPoint",
            "fetchSaveUniverse",
            "fetchEntityList",
            "fetchEditUniverseList",
        ]),

        async getEntityResponseData() {
            this.page = 1;
            if (this.additionalsearchPagination == false) {
                var obj = {
                    entityTypeId: this.entityTypeId,
                    page: this.page,
                    pageSize: this.pageSize,
                    search: this.searchField,
                };
                let response = await getEntityListData(obj);
                response.data.list.forEach((item) => {
                    item["checked"] = false;
                });
                this.entityNameRow = response.data.list;
                this.totalItems = response.data.totalitems;
            } else {
                let response = await additionalSearchApply(
                    this.page,
                    this.pageSize,
                    this.searchField,
                    this.applyButtonArray
                );
                this.entityNameRow = response.data.entities;
                this.totalItems = response.data.total;
            }
            this.dataMatchCheckBoxTrue();
            this.paginationComponentKey += 1;
        },

        async paginateTable(arg) {
            this.showLoader = true;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            if (this.additionalsearchPagination == false) {
                var obj = {
                    entityTypeId: this.entityTypeId,
                    page: this.page,
                    pageSize: this.pageSize,
                    search: this.searchField,
                };
                let response = await getEntityListData(obj);
                response.data.list.forEach((item) => {
                    item["checked"] = false;
                });
                this.entityNameRow = response.data.list;
                this.totalItems = response.data.totalitems;
            } else {
                let response = await additionalSearchApply(
                    this.page,
                    this.pageSize,
                    this.searchField,
                    this.applyButtonArray
                );
                this.entityNameRow = response.data.entities;
                this.totalItems = response.data.total;
            }
            this.dataMatchCheckBoxTrue();
            this.showLoader = false;
        },

        dataMatchCheckBoxTrue() {
            if (this.selectedEntityNameRow.size != 0) {
                for (let item of this.selectedEntityNameRow) {
                    for (let i = 0; i < this.entityNameRow.length; i++) {
                        if (this.entityNameRow[i].entityid == item.entityid) {
                            this.entityNameRow[i].checked = true;
                        }
                    }
                }
            }
        },

        switchUniverseTypes(w) {
            this.currentUniverseType = w.lable;
            if (this.switchUniverseTypeIndex > 0) {
                this.switchUniverse();
            }
            if (this.switchUniverseTypeIndex == 0) {
                this.yesSwitchUniverse();
            }
        },

        switchStaticEntityLevel(val) {
            this.entityTypeId = val.entitytypeid;
            this.selectentitylevel = val.entitytypedescription;
            this.additionalSearchClearAll();
            this.getEntityName();
            this.dataMatchCheckBoxTrue();
        },

        // filter's search functionality: executed when user presses enter key after providing input
        async searchTextEntered() {
            this.showLoader = true;
            this.getEntityResponseData();
            this.showLoader = false;
        },

        // filter's search functionality: executed when user presses clear button in search bar
        searchFieldCleared(e) {
            if (e) e.target.blur();
            this.searchField = "";
            this.showLoader = true;
            this.getEntityResponseData();
            this.showLoader = false;
        },

        reset() {
            this.selectedEntityNameRow = [];
            this.entityNameRow.forEach((item) => {
                if (item.checked == true) {
                    item.checked = false;
                }
            });
            this.selectedEntityName.clear();
        },

        resetStatic() {
            this.selectedEntityNameRow = [];
            this.selectedMatchUploadEntity = [];
            let selectedCheckboxes = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            selectedCheckboxes.forEach((elem) => elem.click());
        },

        async getEntityName() {
            this.showLoader = true;
            // await this.fetchEntityList(this.entityTypeId);
            await this.fetchDynamicDataPoint(this.entityTypeId);
            // this.getEntityDataBind();
            this.additionalSearchDataPoints = this.getDynamicDataPoint;
            this.showLoader = false;
        },

        getEntityDataBind() {
            let response = this.getEntity;
            for (let i = 0; i < response.length; i++) {
                for (let j = 0; j < this.selectedEntityNameRow.length; j++) {
                    if (
                        response[i].entityid ==
                        this.selectedEntityNameRow[j].entityid
                    ) {
                        response[i].checked = true;
                    }
                }
            }
            this.entityNameRow = response;
            this.additionalSearchDataPoints = this.getDynamicDataPoint;
        },

        setActiveItem(event) {
            this.tabsContent.forEach((item) => {
                if (item.id === event.currentTarget.id) {
                    this.selectTab = event.currentTarget.id;
                    item.active = true;
                    if (event.currentTarget.id == "selectEntities") {
                        this.selectEntitiesTabShow = true;
                        this.uploadEntitiesTabShow = false;
                        if (this.selectedMatchUploadEntity.length != 0) {
                            this.reset();
                        }
                    } else if (event.currentTarget.id == "uploadEntities") {
                        this.uploadEntitiesTabShow = true;
                        this.selectEntitiesTabShow = false;
                        if (this.selectedEntityNameRow.length != 0) {
                            this.selectedMatchUploadEntity = [];
                            this.identifier = "";
                            this.uploadEntityClose();
                        }
                    }
                } else {
                    item.active = false;
                }
            });
        },

        handleRowSelectEntityNameEvent(index, event, row) {
            if (event) {
            this.switchUniverseTypeIndex += 1;
                if (
                    !this.selectedEntityNameRow.some(
                        (el) => el.entityid === row.entityid
                    )
                ) {
                    this.selectedEntityNameRow.push(row);
                }
            } else {
                const itemIndex = this.selectedEntityNameRow.findIndex(
                    (element) => element.entityid === row.entityid
                );
                if (itemIndex > -1) {
                    this.selectedEntityNameRow.splice(itemIndex, 1);
                }
            }
            this.$set(this.entityNameRow[index], "checked", event);
        },

        handleMultiSelectionEntityName(event) {
            for (let i = 0; i < this.entityNameRow.length; i++) {
                this.$set(
                    this.entityNameRow[i],
                    "checked",
                    event.target.checked
                );
            }
        },

        changeIdentifier(event) {
            console.log(event);
        },

        //start upload entity functionality
        uploadEntityFile() {
            // empty the array before uploading a new file
            this.uploadedFile = [];
            this.$refs.fileUploadContainer.value = "";

            let fileInputElement = this.$refs.fileUploadContainer;
            fileInputElement.addEventListener(
                "change",
                this.fileHandler,
                false
            );
            fileInputElement.click();
        },

        fileHandler() {
            // If file type is valid then, convert it to JSON object.
            if (
                this.isUploadedFileTypeValid(
                    this.$refs.fileUploadContainer.files[0].name
                )
            ) {
                this.uploadedFile = this.$refs.fileUploadContainer.files[0];
                if (this.uploadedFile.size === 0) {
                    let response = {
                        isError: true,
                        data: {
                            message: "Uploaded file is empty."
                        }
                    }
                    this.notificationResponse({
                        response,
                    });
                    //this.$emit("invalidFile", "Empty File Uploaded");
                } else {
                    this.convertToJson(this.uploadedFile);
                    this.$refs.fileUploadContainer.value = null;
                }
            } else {
                let response = {
                    isError: true,
                    data: {
                        message: "Incorrect file format"
                    }
                }
                this.notificationResponse({
                    response,
                });
                //this.$emit("invalidFile", "Invalid File Type");
            }
        },

        isUploadedFileTypeValid(filename) {
            // regex pattern to validate the file type
            let acceptedFileTypes = /(\.csv|\.xlsx)$/i;

            if (acceptedFileTypes.exec(filename)) {
                return true;
            } else {
                return false;
            }
        },

        convertToJson(file) {
            if (
                this.getUploadedFileType(file.name) === "xlsx" ||
                this.getUploadedFileType(file.name) === "xls"
            ) {
                // converting .xlsx .xls file to JSON object.
                this.convertXlsxToJson(file);
            } else if (this.getUploadedFileType(file.name) === "csv") {
                // converting .csv file to JSON object.
                this.convertCsvToJson(file);
            }
        },

        getUploadedFileType(filename) {
            return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
        },

        convertXlsxToJson(file) {
            let reader = new FileReader();
            reader.readAsBinaryString(file);

            let xlsxObj = {};
            reader.onload = (event) => {
                let modifiedXlsxObj = [];
                let fileData = event.target.result;
                let highestIndexLengthOfUploadedFile = 0;

                // workbook refers to the complete excel document
                let workbook = XLSX.read(fileData, { type: "binary" });
                workbook.SheetNames.forEach((sheet) => {
                    xlsxObj = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
                        header: 1,
                    });
                });

                modifiedXlsxObj.push(xlsxObj[0]);

                for (let i = 1; i < xlsxObj.length; i++) {
                    let internalObj = xlsxObj[i];
                    let emptyCellCount = 0;

                    // to check if empty rows exists or not
                    for (let k = 0; k < internalObj.length; k++) {
                        if (
                            !(
                                internalObj[k] &&
                                internalObj[k].toString().trim()
                            )
                        ) {
                            emptyCellCount++;
                        }
                    }

                    if (emptyCellCount !== internalObj.length) {
                        if (
                            internalObj !== undefined &&
                            internalObj.length !== 0
                        ) {
                            if (
                                internalObj.length < xlsxObj[0].length &&
                                internalObj[internalObj.length] === undefined
                            ) {
                                xlsxObj[i][internalObj.length] = null;
                            }
                            for (var j = 0; j < internalObj.length; j++) {
                                if (
                                    internalObj[j] == undefined ||
                                    internalObj[j].length == 0
                                ) {
                                    xlsxObj[i][j] = null;
                                }
                            }
                            modifiedXlsxObj.push(xlsxObj[i]);
                        }
                    }
                }

                if (
                    modifiedXlsxObj.length === 0 ||
                    (modifiedXlsxObj[0] === undefined &&
                        modifiedXlsxObj.length === 1 &&
                        modifiedXlsxObj[1] === undefined) ||
                    modifiedXlsxObj[0].length === 0
                ) {
                    //this.$emit("invalidFile", "Empty File Uploaded");
                } else {
                    for (let l = 0; l < modifiedXlsxObj.length; l++) {
                        if (
                            modifiedXlsxObj[l].length >
                            highestIndexLengthOfUploadedFile
                        ) {
                            highestIndexLengthOfUploadedFile =
                                modifiedXlsxObj[l].length;
                        }
                    }

                    for (let i = 0; i < modifiedXlsxObj.length; i++) {
                        for (
                            let j = 0;
                            j < highestIndexLengthOfUploadedFile;
                            j++
                        ) {
                            if (modifiedXlsxObj[i][j] === undefined) {
                                modifiedXlsxObj[i][j] = null;
                            }
                        }
                    }
                    var merged = [].concat.apply([], modifiedXlsxObj);
                    this.uploadEntityIds = merged.splice(1);
                    this.uploadEntityFileName = file.name;
                    if (this.uploadEntityIds.length != 0) {
                        this.uploadingFileSpinner = true;
                        this.uploadingFiles = false;
                    }
                    this.callUploadEntityApi();
                }
            };
        },

        convertCsvToJson(file) {
            let reader = new FileReader();
            reader.readAsBinaryString(file);

            let csvObj = [];
            reader.onload = (event) => {
                let modifiedCsvObj = [];
                let fileData = event.target.result;
                let lines = fileData.split("\n");
                let highestIndexLengthOfUploadedFile = 0;

                // separating headers and trimming all new line characters
                let headers = lines[0].split(",").map((element) => {
                    return element.trim();
                });
                csvObj.push(headers);

                // replacing "" to null and trimming all new line characters
                for (let i = 1; i < lines.length; i++) {
                    csvObj.push(
                        lines[i]
                            .trim()
                            .split(",")
                            .map((element) => {
                                if (element) {
                                    return element.trim();
                                } else {
                                    return null;
                                }
                            })
                    );
                }

                for (let i = 0; i < csvObj.length; i++) {
                    let internalObj = csvObj[i];
                    let emptyCellCount = 0;

                    // to check if empty rows exists or not
                    for (let k = 0; k < internalObj.length; k++) {
                        if (
                            internalObj[k] === null ||
                            internalObj[k].trim() === ""
                        ) {
                            emptyCellCount++;
                        }
                    }

                    if (emptyCellCount !== internalObj.length) {
                        if (
                            internalObj.length < csvObj[0].length &&
                            internalObj[internalObj.length] === undefined
                        ) {
                            csvObj[i][internalObj.length] = null;
                        }
                        for (var j = 0; j < internalObj.length; j++) {
                            if (
                                internalObj[j] == undefined ||
                                internalObj[j].length == 0
                            ) {
                                csvObj[i][j] = null;
                            }
                        }
                        modifiedCsvObj.push(csvObj[i]);
                    }
                }

                if (
                    modifiedCsvObj.length === 0 ||
                    (modifiedCsvObj[0] === undefined &&
                        modifiedCsvObj.length === 1 &&
                        modifiedCsvObj[1] === undefined)
                ) {
                    //this.$emit("invalidFile", "Empty File Uploaded");
                } else {
                    for (let l = 0; l < modifiedCsvObj.length; l++) {
                        if (
                            modifiedCsvObj[l].length >
                            highestIndexLengthOfUploadedFile
                        ) {
                            highestIndexLengthOfUploadedFile =
                                modifiedCsvObj[l].length;
                        }
                    }

                    for (let i = 0; i < modifiedCsvObj.length; i++) {
                        for (
                            let j = 0;
                            j < highestIndexLengthOfUploadedFile;
                            j++
                        ) {
                            if (modifiedCsvObj[i][j] === undefined) {
                                modifiedCsvObj[i][j] = null;
                            }
                        }
                    }
                    var merged = [].concat.apply([], modifiedCsvObj);
                    this.uploadEntityIds = merged.splice(1);
                    this.uploadEntityFileName = file.name;
                    if (this.uploadEntityIds.length != 0) {
                        this.uploadingFileSpinner = true;
                        this.uploadingFiles = false;
                    }
                    this.callUploadEntityApi();
                }
            };
        },

        async callUploadEntityApi() {
            var obj = {
                Header: this.identifier,
                Rows: this.uploadEntityIds,
            };
            let response = await getUploadEntity(obj);
            this.uploadEntityResponse = response;
            if (response.isError == false) {
                this.uploadEntityTotal = response.data.totalcount;
                this.uploadEntityMatched = response.data.matchedcount;
                this.uploadEntityUnmatched = response.data.errorcount;
                if (
                    response.data.entityid.length != 0 ||
                    response.data.entityid != undefined
                ) {
                    this.selectedMatchUploadEntity = response.data.entityid;
                }
                if (this.uploadEntityTotal === this.uploadEntityMatched) {
                    this.uploadingFileSpinner = false;
                    this.uploadingFiles = false;
                    this.uploadEntityGetResponse = true;
                    this.uploadEntitySuccess = true;
                } else if (
                    this.uploadEntityTotal === this.uploadEntityUnmatched
                ) {
                    this.uploadingFileSpinner = false;
                    this.uploadingFiles = false;
                    this.uploadEntityGetResponse = true;
                    this.uploadEntityError = true;
                } else {
                    this.uploadingFileSpinner = false;
                    this.uploadingFiles = false;
                    this.uploadEntityGetResponse = true;
                    this.uploadEntityWarning = true;
                }
            } else if (response.isError == true) {
                const notificationData = {
                    response,
                };
                this.notificationResponse(notificationData);
                this.uploadEntityReset();
            }
        },

        downloadUploadEntity() {
            var fileName = this.uploadEntityFileName.split(".");
            fileName.pop();
            const url = window.URL.createObjectURL(
                new Blob([this.uploadEntityResponse.data.filestring], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
        },

        uploadEntityClose() {
            this.uploadEntityReset();
        },

        uploadEntityReset() {
            this.uploadingFiles = true;
            this.uploadingFileSpinner = false;
            this.uploadEntityWarning = false;
            this.uploadEntityError = false;
            this.uploadEntitySuccess = false;
            this.uploadEntityGetResponse = false;
        },

        //end upload entity functionality

        deleteEntityStatic(data) {
            const selectedEntityIndex =
                this.selectedEntityNameRow.indexOf(data);
            // const entityNameIndex = this.entityNameRow.indexOf(data);
            const findElement = this.entityNameRow.filter(x => x.entityid === data.entityid);
            const entityNameIndex = this.entityNameRow.indexOf(findElement[0]);
            if (selectedEntityIndex > -1) {
                this.selectedEntityNameRow.splice(selectedEntityIndex, 1);
            }
            if (entityNameIndex > -1) {
                this.entityNameRow[entityNameIndex].checked = false;
            }
        },


        //Cancel universe function
        cancelButton() {
            this.$router.push({ name: "Reporting Universe" });
        },

        //Save universe function
        async saveButton() {
            if (this.universeType == "Dynamic") {
                let DynamicRequest = {
                    universeId: null,
                    universeName: "",
                    isStatic: false,
                    isShared: false,
                    EntityTypeId: 0,
                    dataPointList: [],
                    entityIds: [0],
                };
                if (this.createUniverse != "Create Universe") {
                    DynamicRequest.universeId = this.editUniverse.listid;
                }
                DynamicRequest.universeName = this.universename;
                DynamicRequest.EntityTypeId = this.dynamicEntityTypeId;
                DynamicRequest.datapointList = this.DataPointList;

                let id = this.showAddedCriteria.map((item) => item.DatapointId);
                let operator = this.showAddedCriteria.map(
                    (item) => item.OperatorCondition
                );
                let value = this.showAddedCriteria.map((item) =>
                    item.Value.map((resultItem) => resultItem.value)
                );
                let DataPointTypeId = this.showAddedCriteria.map(
                    (item) => item.DataPointTypeId
                );
                let EnumId = this.showAddedCriteria.map((item) => item.EnumId);
                let ReferenceTypeId = this.showAddedCriteria.map(
                    (item) => item.ReferenceTypeId
                );

                for (let i = 0; i < operator.length; i++) {
                    this.DataPointList.push({
                        datapointId: id[i],
                        operatorCondition: operator[i],
                        DataPointTypeId: DataPointTypeId[i],
                        EnumId: EnumId[i],
                        ReferenceTypeId: ReferenceTypeId[i],
                        values: value[i],
                    });

                }
                this.DataPointList.forEach((item)=>{
                    if(item.operatorCondition === 'Between'){
                        item.values = item.values[0]
                    }                
                    })
                if (this.createUniverse == "Create Universe") {
                    await this.fetchSaveUniverse(DynamicRequest);
                    let response = this.getSaveUniverse;
                    const notificationData = {
                        response,
                        ntfnMsg: "Your universe has been saved.",
                        ntfnType: "success",
                        ntfnKey: "success-default",
                    };
                    this.notificationResponse(notificationData);
                } else {
                    await this.fetchSaveUniverse(DynamicRequest);
                    let response = this.getSaveUniverse;
                    const notificationData = {
                        response,
                        ntfnMsg: "Your universe has been updated.",
                        ntfnType: "success",
                        ntfnKey: "success-default",
                    };
                    this.notificationResponse(notificationData);
                }
            }
            //Save static data
            else if (this.universeType == "Static") {
                var array = [];
                if (this.selectedEntityNameRow != 0) {
                    this.selectedEntityNameRow.forEach((item) => {
                        array.push(item.entityid);
                    });
                } else {
                    array = this.selectedMatchUploadEntity;
                }

                if (this.createUniverse == "Create Universe") {
                    this.staticRequest = {
                        UniverseName: this.universename,
                        isStatic: true,
                        EntityIds: array,
                        universeId: null,
                        isShared: false,
                        entityTypeId: 0,
                        dataPointList: [],
                    };
                    await this.fetchSaveUniverse(this.staticRequest);
                    let response = this.getSaveUniverse;
                    const notificationData = {
                        response,
                        ntfnMsg: "Your universe has been saved.",
                        ntfnType: "success",
                        ntfnKey: "success-default",
                    };
                    this.notificationResponse(notificationData);
                } else {
                    this.staticRequest = {
                        UniverseName: this.universename,
                        isStatic: true,
                        EntityIds: array,
                        universeId: this.editUniverse.listid,
                        isShared: false,
                        entityTypeId: 0,
                        dataPointList: [],
                    };
                    await this.fetchSaveUniverse(this.staticRequest);
                    let response = this.getSaveUniverse;
                    const notificationData = {
                        response,
                        ntfnMsg: "Your universe has been updated.",
                        ntfnType: "success",
                        ntfnKey: "success-default",
                    };
                    this.notificationResponse(notificationData);
                }
            }
        },

        async notificationResponse({ response, ntfnMsg, ntfnType, ntfnKey }) {
            if (response.isError == false) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                setTimeout(() => {
                        this.$router.push({
                            name: "Reporting Universe",
                        });
                    }, 2000);
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.DataPointList = [];
                this.showNotification = true;
            }
        },

        removeSingleUploadEntity(value) {
            this.selectedMatchUploadEntity.forEach((item, index) => {
                if (item.entityid == value.entityid) {
                    this.selectedMatchUploadEntity.splice(index, 1);
                }
            });
        },

        async onApplyClick() {
            this.showLoader = true;
            let obj={}
            if(this.additionalsearchoperator == 'Between'){
                obj = {
                    dataPointId: this.additionalsearchdatapoint,
                    operatorCondition: this.additionalsearchoperator,
                    dataPointTypeId: this.addSearchDataTypeId,
                    enumId: this.addSearchEnumId,
                    referenceTypeId: this.addSearchRefEntityTypeId,
                    values: [this.additionalsearchvalueFrom,this.additionalsearchvalueTo],
                };
                if(this.addSearchDataTypeId == 3){
                    this.additionalsearchvalue=this.convertAdditionalSearchDate(this.additionalsearchvalueFrom) + ' - ' + this.convertAdditionalSearchDate(this.additionalsearchvalueTo)
                }else{
                    this.additionalsearchvalue=this.additionalsearchvalueFrom + ' - ' + this.additionalsearchvalueTo
                }
            }else{
                obj = {
                    dataPointId: this.additionalsearchdatapoint,
                    operatorCondition: this.additionalsearchoperator,
                    dataPointTypeId: this.addSearchDataTypeId,
                    enumId: this.addSearchEnumId,
                    referenceTypeId: this.addSearchRefEntityTypeId,
                    values: [this.additionalsearchvalue],
                };
                if(this.addSearchDataTypeId == 3){
                    this.additionalsearchvalue=this.convertAdditionalSearchDate(this.additionalsearchvalue)
                }
            }
            this.applyButtonArray.push(obj);
            this.additionalsearchdatapointtemp = this.additionalsearchdatapoint;
            this.additionalsearchoperatortemp = this.additionalsearchoperator;
            this.addSearchDataTypeIdtemp = this.addSearchDataTypeId;
            this.addSearchEnumIdtemp = this.addSearchEnumId;
            this.addSearchRefEntityTypeIdtemp = this.addSearchRefEntityTypeId;
            this.additionalsearchvaluetemp = this.additionalsearchvalue;
            this.page = 1;
            let response = await additionalSearchApply(
                this.page,
                this.pageSize,
                this.searchField,
                this.applyButtonArray
            );
            this.additionalsearchPagination = true;
            if (this.additionalSearchValues.length != 0) {
                this.objDupData = {
                    dataPoint: this.additionalsearchdatapointname,
                    operator: this.additionalsearchoperator,
                    value: this.additionalsearchvaluetext,
                };
            } else {
                this.objDupData = {
                    dataPoint: this.additionalsearchdatapointname,
                    operator: this.additionalsearchoperator,
                    value: this.additionalsearchvalue,
                };
            }
            this.duplicateAdditionalSearch.push(this.objDupData);
            this.entityNameRow = response.data.entities;
            this.totalItems = response.data.total;
            this.paginationComponentKey += 1;
            this.additionalsearchdatapoint = "";
            this.additionalsearchoperator = "";
            this.additionalsearchvalue = "";
            this.additionalsearchvalueFrom = "";
            this.additionalsearchvalueTo = "";
            await this.fetchDynamicDataPoint(this.entityTypeId);
            this.additionalSearchDataPoints = this.getDynamicDataPoint;
            this.getDynamicDataPoint2 = []
            this.showLoader = false;
        },

        async additionalSearchClearAll() {
            this.additionalsearchdatapoint = "";
            this.additionalsearchoperator = "";
            this.additionalsearchvalue = "";
            this.additionalsearchvalueFrom = "";
            this.additionalsearchvalueTo = "";
            this.duplicateAdditionalSearch = [];
            this.applyButtonArray = [];
            this.showLoader = true;
            this.additionalsearchPagination = false;
            await this.fetchDynamicDataPoint(this.entityTypeId);
            this.additionalSearchDataPoints = this.getDynamicDataPoint;
            this.getDynamicDataPoint2 = []
            this.getEntityResponseData();
            this.showLoader = false;
        },

        async additionalSearchClose() {
            this.showLoader = true;
            this.additionalsearchdatapoint = "";
            this.additionalsearchoperator = "";
            this.additionalsearchvalue = "";
            this.additionalsearchvalueFrom = "";
            this.additionalsearchvalueTo = "";
            await this.fetchDynamicDataPoint(this.entityTypeId);
            this.additionalSearchDataPoints = this.getDynamicDataPoint;
            this.getDynamicDataPoint2 = []
            this.showLoader = false;
        },

        async additionalSearchArrayClose(index) {
            this.showLoader = true;
            this.duplicateAdditionalSearch.splice(index, 1);
            this.applyButtonArray.splice(index, 1);
            this.page = 1;
            let response = await additionalSearchApply(
                this.page,
                this.pageSize,
                this.searchField,
                this.applyButtonArray
            );
            this.entityNameRow = response.data.entities;
            this.totalItems = response.data.total;
            this.paginationComponentKey += 1;
            if (this.duplicateAdditionalSearch.length == 0) {
                this.additionalsearchPagination = false;
                this.getEntityResponseData();
            }
            this.showLoader = false;
        },

        //change datapoint in additional search
        changeAdditionalSearchDataPoint() {
            this.additionalsearchoperator = "";
            this.additionalsearchvalue = "";
            this.showLoader = true;
            if (
                this.getDynamicDataPoint2 == undefined ||
                this.getDynamicDataPoint2.length == 0
            ) {
                this.getDynamicDataPoint.forEach((item) => {
                    if (this.additionalsearchdatapoint == item.id) {
                        this.additionalsearchdatapointname = item.name;
                        this.selectDataPointData = item;
                    }
                });
            } else {
                this.getDynamicDataPoint2.forEach((item2) => {
                    if (this.additionalsearchdatapoint == item2.id) {
                        this.selectDataPointData = item2;
                        this.additionalsearchdatapointname = item2.name;
                    }
                });
            }
            this.duplicateAdditionalSearch.forEach(item =>{
                if(item.dataPoint==this.additionalsearchdatapointname){
                    this.additionalsearchdatapoint = "";
                    this.additionalsearchdatapointname = "";
                    this.showLoader = false;
                    this.notificationKey = "error-default";
                    this.notificationMessage = "Data point already selected";
                    this.notificationType = "error";
                    this.showNotification = true;
                    setTimeout(() => {
                        this.showNotification = false;
                    }, 2000);
                }
            })
            this.addSearchCriteriaCondition();
        },

        changeAdditionalSearchValue() {
            this.additionalSearchValues.forEach((item) => {
                if (item.value == this.additionalsearchvalue) {
                    this.additionalsearchvaluetext = item.text;
                }
            });
        },

        async addSearchCriteriaCondition() {
            this.addSearchEnumId = this.selectDataPointData.enumid;
            this.addSearchDataTypeId = this.selectDataPointData.datatypeid;
            this.addSearchRefEntityTypeId =
                this.selectDataPointData.referencedentitytypeid;
            if (this.addSearchEnumId != null) {
                let response = await getSelectForMultipleEnum(
                    this.addSearchEnumId,
                    []
                );
                this.additionalSearchValues = response.data;
                this.additionalSearchOperators = [];
                this.additionalsearchoperator = "In";
            } else {
                if (this.addSearchDataTypeId == 0) {
                    this.additionalSearchDataPoints =
                        this.selectDataPointData.datapointgroups;
                    this.getDynamicDataPoint2 =
                        this.selectDataPointData.datapointgroups;
                    this.additionalSearchOperators = [];
                    this.additionalsearchoperator = "In";
                } else if (
                    this.addSearchDataTypeId == 1 ||
                    this.addSearchDataTypeId == 2
                ) {
                    this.additionalSearchOperators = this.getOptionsforValue;
                    this.additionalSearchValues = [];
                } else if (
                    this.addSearchDataTypeId == 3 ||
                    this.addSearchDataTypeId == 4
                ) {
                    this.additionalSearchOperators = this.getOptionsforDate;
                    this.additionalSearchValues = [];
                } else if (
                    this.addSearchDataTypeId == 5 ||
                    this.addSearchDataTypeId == 6
                ) {
                    this.additionalSearchOperators =
                        this.getOptionsforSingleSelection;
                    this.additionalSearchValues = [];
                } else if (this.addSearchDataTypeId == 7) {
                    this.additionalSearchOperators = [];
                    this.additionalsearchoperator = "In";
                    let response = await getSelectForReferenceTypeId(
                        this.addSearchRefEntityTypeId
                    );
                    this.additionalSearchValues = response.data;
                }
            }
            this.showLoader = false;
        },

        // -----------

        // Add Criteria after selecting Entity Level
        async addDynamicEntityLevel() {
            this.DynamicEntityLevelIndex += 1;
            this.switchUniverseTypeIndex += 1;
            this.AddCriteria = "Add Criteria";
            this.dynamicInputValue = "";
            this.selectedOperator = "";
            this.AddCriteriaId = 0;
            this.showSelectedValue = [];
            this.isSelectionValue = false;
            this.getselectedValue = [];
            this.selectedValueCount = 0;
        
            this.showLoader = true;
            this.isAddCriteria = true;
            this.isEntityLevelSectionExpanded = false;
            this.dynamicUniverseEntityLevelSectionUpdate++;
            this.addEntityLevel = false;
            this.EntryLevel = this.currentEntryLevel;
            await this.fetchDynamicDataPoint(this.dynamicEntityTypeId);
            this.getDynamicSelectionList = this.getDynamicDataPoint;
            this.DynamicSelectionArray = this.getDynamicSelectionList;
            this.showLoader = false;
        },

        // remove any criteria by clicking Remove button
        closeSelectionValue() {
            // this.showSelectedValue = [];
            this.AddCriteria = "Add Criteria";
            this.isSelectionValue = false;
            this.dynamicDataTypeZero = false;
            // this.getDynamicSelectionList = this.getDynamicDataPoint;
            this.getselectedValue = [];
            this.selectedValueCount = 0;
            this.dataPointDescription = "";
            this.getSelectionValue.forEach((item) => {
                let data = item;
                data.selected = false;
            });
            this.addCriteriaRemoveButton = false;
            this.dataPointHeading = "";
            this.dynamicInputValue = "";
            this.dynamicInputValueTo = "";
        },

        // Add selected criteria from list and API call
        async selectionofValue(array) {
            this.addCriteriaRemoveButton = true;
            this.searchDynamicEntityName = "";
            this.AddCriteriaId = array.id;
            this.AddCriteria = array.name;
            this.dynamicEnumId = array.enumid;
            this.dynamicDataTypeId = array.datatypeid;
            this.referenceEntityTypeId = array.referencedentitytypeid;
            if (this.dynamicEnumId == null) {
                // input-field will be on
                this.dynamicDataTypeId = array.datatypeid;
                if (this.dynamicDataTypeId == 7) {
                    this.dynamicDataTypeZero = false;
                    this.dynamicInputLevel = false;
                    this.referenceEntityTypeId = array.referencedentitytypeid;
                    this.showLoader = true;
                    var result = await getSelectForReferenceTypeId(
                        this.referenceEntityTypeId,
                        this.sampleArray
                    );
                    this.getSelectionValue = result.data;
                    this.DynamicTemporaryArray = this.getSelectionValue;
                    this.showLoader = false;
                } else if (this.dynamicDataTypeId == 0) {
                    this.dynamicDataTypeZero = true;
                    this.dynamicInputLevel = true;
                    this.dynamicDataTypeZeroSelectionList =
                        array.datapointgroups;
                } else {
                    this.dynamicDataTypeZero = false;
                    this.dynamicInputLevel = true;
                }
            }
            // checkbox field will be on
            else {
                this.dynamicInputLevel = false;
                this.showLoader = true;
                var response = await getSelectForMultipleEnum(
                    this.dynamicEnumId,
                    this.sampleArray
                );
                this.getSelectionValue = response.data;
                this.DynamicTemporaryArray = this.getSelectionValue;
                this.showLoader = false;
            }
            this.isSelectionValue = true;
        },

        // add or select a value and remove it using checkbox
        selectedValue(array) {
            if (array.selected == false) {
                array.selected = true;
                this.isChecked = true;
                this.selectedValueCount++;
                let data = {};
                data = array;
                this.getselectedValue.push(data);
            } else {
                array.selected = false;
                this.selectedValueCount--;
                this.getselectedValue.forEach((item, index) => {
                    let data = item;

                    if (data == array) {
                        this.getselectedValue.splice(index, 1);
                    }
                });
            }
            if (this.selectedValueCount == 0) {
                this.isChecked = false;
            }
        },

        // Add another criteria
        addAnotherCriteria() {

            let response = this.showAddedCriteria.filter(
                (item) => item.Title === this.AddCriteria
            );
            if(response.length != 0){
                 this.showAddedCriteria = this.showAddedCriteria.filter(
                (item) => item.Title !== this.AddCriteria
                );
            }

            this.getselectedValue.forEach((item) => {
                let data = item;

                this.showSelectedValue.push(data);
            });
            let AddedCriteria = {
                DatapointId: 0,
                Title: "",
                OperatorCondition: "In",
                isSelectionValue: false,
                dynamicInputLevel: false,
                isSectionExpanded: false,
                EnumId: 0,
                DataPointTypeId: 0,
                ReferenceTypeId: 0,
                dynamicDataTypeId: 0,
                selectedValueCount: 0,
                getselectedValue: [],
                getSelectionValue: [],
                Value: [],
            };

            AddedCriteria.DatapointId = this.AddCriteriaId;
            AddedCriteria.Title = this.AddCriteria;
            AddedCriteria.Value = this.showSelectedValue;
            AddedCriteria.isSelectionValue = this.isSelectionValue;
            AddedCriteria.dynamicInputLevel = this.dynamicInputLevel;
            AddedCriteria.dynamicDataTypeId = this.dynamicDataTypeId;
            AddedCriteria.selectedValueCount = this.selectedValueCount;
            AddedCriteria.getselectedValue = this.getselectedValue;
            AddedCriteria.EnumId = this.dynamicEnumId;
            AddedCriteria.DataPointTypeId = this.dynamicDataTypeId;
            AddedCriteria.ReferenceTypeId = this.referenceEntityTypeId;

            AddedCriteria.getSelectionValue = this.getSelectionValue;
            this.showAddedCriteria.push(AddedCriteria);
            // this.getDynamicSelectionList = this.getDynamicSelectionList.filter(
            //     (item) => item.name !== this.AddCriteria
            // );
            this.resetDynamicUniverseMultipleSelectionDataPoint();
            },

        // cancel single selected value using remove button
        cancelSelectedValue(array) {
            this.selectedValueCount--;
            this.getselectedValue.forEach((item, index) => {
                if (item == array) {
                    item.selected = false;
                    this.getselectedValue.splice(index, 1);
                }
            });
            if (this.selectedValueCount == 0) {
                this.isChecked = false;
            }
        },

        // called when user want to change Universe Type
        switchUniverse() {
            this.toggleUniverseRadioButton = !this.toggleUniverseRadioButton;
        },

        noSwitchUniverse() {
            if (this.switchUniverseType == 1) {
                this.switchUniverseType = 2;
            } else {
                this.switchUniverseType = 1;
            }
        },

        async yesSwitchUniverse() {
            this.switchUniverseTypeIndex = 0;
            this.universeType = this.currentUniverseType;
            this.resetStatic();
            if (this.switchUniverseType == 2) {
                this.showLoader = true;
                this.changeStaticEntityLevel = 1;
                this.getEntityResponseData();
                // await this.fetchEntityList(1);
                await this.fetchDynamicDataPoint(1);
                // this.entityNameRow = this.getEntity;
                this.showLoader = false;
            } else if (this.switchUniverseType == 1) {
                this.selectedDynamicEntityLevel = 0;
                this.showAddedCriteria = [];
                this.DynamicEntityLevelIndex = 0;
                this.isAddCriteria = false;
                this.currentEntryLevel = "";
                this.searchDynamicEntityName="";
            this.addEntityLevel = true;
            this.isselectedDynamicEntityLevel = false;
            }
        },

        // Route to Reporting Universe page
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // switch DYnamic Entity-Level
        switchDynamicEntityLevel(event) {
            this.isselectedDynamicEntityLevel = true;
            if (this.DynamicEntityLevelIndex == 0) {
                this.dynamicEntityTypeId = event.entitytypeid;
                this.currentEntryLevel = event.entitytypedescription;
            } else {
                this.DynamicEntityLevelChange = !this.DynamicEntityLevelChange;
                this.DynamicEntityLevelChange = true;
                this.DynamicTemporaryEntityId = event.entitytypeid;
                this.DynamicTemporaryEntryLevel = event.entitytypedescription;
            }
        },

        // switching dynamic entity-level
        noSwitchDynamicEntityLevel() {
            this.DynamicEntityLevelChange = !this.DynamicEntityLevelChange;
            this.selectedDynamicEntityLevel = this.dynamicEntityTypeId;
            this.isEntityLevelSectionExpanded = false;
            this.dynamicUniverseEntityLevelSectionUpdate++;
        },

        yesSwitchDynamicEntityLevel() {
            this.DynamicEntityLevelChange = !this.DynamicEntityLevelChange;
            this.showAddedCriteria = [];
            this.DynamicEntityLevelIndex = 0;
            this.isAddCriteria = false;
            this.selectedDynamicEntityLevel = this.DynamicTemporaryEntityId;
            this.dynamicEntityTypeId = this.DynamicTemporaryEntityId;
            this.currentEntryLevel = this.DynamicTemporaryEntryLevel;
            this.addEntityLevel = true;
            this.searchDynamicEntityName = "";
        },

        // add description of data point
        descriptionOfDataPoint(text, name) {
            this.dataPointDescription = text;
            this.dataPointHeading = name;
        },

        // added Operator value criteria
        addOperatorValue() {
            let response = this.showAddedCriteria.filter(
                (item) => item.Title === this.AddCriteria
            );
            if(response.length != 0){
                 this.showAddedCriteria = this.showAddedCriteria.filter(
                (item) => item.Title !== this.AddCriteria
                );
            }
            let AddedCriteria = {
                DatapointId: 0,
                Title: "",
                OperatorCondition: "",
                isSelectionValue: false,
                dynamicInputLevel: false,
                isSectionExpanded: false,
                EnumId: 0,
                DataPointTypeId: 0,
                ReferenceTypeId: 0,
                dynamicDataTypeId: 0,
                selectedValueCount: 0,
                getselectedValue: [],
                getSelectionValue: [],
                Value: [],
            };
            let array = [];
            let arrayForBetween = [];
            AddedCriteria.DatapointId = this.AddCriteriaId;
            AddedCriteria.Title = this.AddCriteria;
            AddedCriteria.OperatorCondition = this.selectedOperator;
            AddedCriteria.isSelectionValue = this.isSelectionValue;
            AddedCriteria.dynamicInputLevel = this.dynamicInputLevel;

            AddedCriteria.EnumId = this.dynamicEnumId;
            AddedCriteria.DataPointTypeId = this.dynamicDataTypeId;
            AddedCriteria.ReferenceTypeId = this.referenceEntityTypeId;

            AddedCriteria.dynamicDataTypeId = this.dynamicDataTypeId;
            if (this.dynamicDataTypeId == 1 || this.dynamicDataTypeId == 2) {
                AddedCriteria.getSelectionValue = this.getOptionsforValue;
            } else if (
                this.dynamicDataTypeId == 3 ||
                this.dynamicDataTypeId == 4
            ) {
                AddedCriteria.getSelectionValue = this.getOptionsforDate;
            } else if (
                this.dynamicDataTypeId == 5 ||
                this.dynamicDataTypeId == 6
            ) {
                AddedCriteria.getSelectionValue =
                    this.getOptionsforSingleSelection;
            }

            array.text = this.dynamicInputValue;
            array.value = this.dynamicInputValue;
            if (this.selectedOperator == "Between") {
                arrayForBetween.text = this.dynamicInputValueTo;
                arrayForBetween.value = this.dynamicInputValueTo;
                array.text = [array.text , arrayForBetween.text];
                array.value = [array.value , arrayForBetween.value];
                this.splitDateOrTime(array.text);
            }
            AddedCriteria.Value = [array];
            this.showAddedCriteria.push(AddedCriteria);
            
            
            this.resetDynamicUniverseSingleSelectionDataPoint()
            },

        // remove added Criteria
        removeAddedCriteria(text) {
            this.showAddedCriteria.forEach((item, index) => {
                if (item.Title == text) {
                    // item.selected = false;
                    this.showAddedCriteria.splice(index, 1);
                }
            });
            // this.getDynamicDataPoint.forEach((item) => {
            //     if (item.name == text) {
            //         this.getDynamicSelectionList.push(item);
            //     }
            //     this.DynamicSelectionArray = this.getDynamicSelectionList;
            // });
        },

        // After edit if value is NULL then add button will be disable
        editInputValue(val) {
            if (val != "") {
                this.isSelectedOperator = true;
                this.dynamicEditOperatorValue = val;
            } else {
                this.isSelectedOperator = false;
            }
        },

        // event for expanding added criteria section
        expandedAddedCriteria(array) {
            if (array.OperatorCondition == "Between") {
                array.Value.forEach((item) => {
                    let data = item.text;
                    this.splitDateOrTime(data);
                });
            }

            this.showAddedCriteria.forEach((item) => {
                if (item == array) {
                    item.isSectionExpanded = true;
                } else {
                    item.isSectionExpanded = false;
                }
                this.dynamicUniverseCriteriaSectionUpdate++;
            });
            this.addedCriteriaDataListObject = array;
        },

        // if editMode = On then add checkbox value
        editAnotherCriteria(array) {
            this.addedCriteriaDataListObject.Value =
                this.addedCriteriaDataListObject.getselectedValue;
            array = this.addedCriteriaDataListObject;
            array.isSectionExpanded = false;
            this.dynamicUniverseCriteriaSectionUpdate++;
            // this.addedCriteriaDataListObject = [];
        },

        // if editMode = ON then add operator with value
        editOperatorValue(array) {
            if (array.OperatorCondition == "Between") {
                array.Value.forEach((item) => {
                    this.betweenDateFrom = this.betweenFromInputValue;
                    this.betweenDateTo = this.betweenToInputValue;
                    let fromDate = this.betweenFromInputValue;
                    let toDate = this.betweenToInputValue;
                    item.text = [fromDate,toDate];
                    let data = item.text;
                    item.value = data;
                });
            } else if (array.OperatorCondition != "Between") {
                array.Value.forEach((item) => {
                    item.text = this.dynamicEditOperatorValue;
                    item.value = this.dynamicEditOperatorValue;
                });
            }
            array.isSectionExpanded = false;

            this.dynamicUniverseCriteriaSectionUpdate++;
        },

        // if editMode = ON then edit selected value
        editSelectedValue(array) {
            if (array.selected == false) {
                array.selected = true;

                this.isSelectedCriteria = true;
                this.addedCriteriaDataListObject.selectedValueCount++;
                let data = {};
                data = array;
                this.addedCriteriaDataListObject.getselectedValue.push(data);
            } else {
                array.selected = false;
                this.addedCriteriaDataListObject.selectedValueCount--;
                this.addedCriteriaDataListObject.getselectedValue.forEach(
                    (item, index) => {
                        let data = item;

                        if (data == array) {
                            this.addedCriteriaDataListObject.getselectedValue.splice(
                                index,
                                1
                            );
                        }
                    }
                );
            }
            if (this.addedCriteriaDataListObject.selectedValueCount == 0) {
                this.isSelectedCriteria = false;
            }
        },

        // if editMode = ON then cancel selected value
        editCancelSelectedValue(array) {
            this.addedCriteriaDataListObject.selectedValueCount--;
            this.addedCriteriaDataListObject.getselectedValue.forEach(
                (item, index) => {
                    if (item == array) {
                        item.selected = false;
                        this.addedCriteriaDataListObject.getselectedValue.splice(
                            index,
                            1
                        );
                    }
                }
            );
            if (this.addedCriteriaDataListObject.selectedValueCount == 0) {
                this.isSelectedCriteria = false;
            }
        },

        // search dynamic search-input
        searchInput(val) {
            if (this.isSelectionValue === false) {
                if (this.searchDynamicEntityName == "") {
                    this.getDynamicSelectionList = this.DynamicSelectionArray;
                }
                this.searchDataPoint(val);
            } else {
                this.searchDataPointValue(val);
            }
        },

        searchDataPoint(searched){
     this.getDynamicSelectionList=this.DynamicSelectionArray.filter(c=>{
       return (c.name.toUpperCase().indexOf(searched.toUpperCase()))>-1
     })
    },
    
    searchDataPointValue(searched){
     this.getSelectionValue=this.DynamicTemporaryArray.filter(c=>{
       return (c.text.toUpperCase().indexOf(searched.toUpperCase()))>-1
     })
    },

        // clear dynamic search-input
        clearSearchInput() {
            if (this.isSelectionValue === false) {
                this.getDynamicSelectionList = this.DynamicSelectionArray;
            } else {
                this.getSelectionValue = this.DynamicTemporaryArray;
            }
        },

        editOperator() {
            if (this.addeddatapoint.OperatorCondition == "") {
                this.isSelectedOperator = false;
            }
            if (this.addeddatapoint.OperatorCondition == "Between") {
                this.betweenFromInputValue = "";
                this.betweenToInputValue = "";
            }
        },

        // reset dynamic universe after adding any data point
        async resetDynamicUniverseMultipleSelectionDataPoint(){
            
            this.showLoader = true;
            await this.fetchDynamicDataPoint(this.dynamicEntityTypeId);
            this.getDynamicSelectionList = this.getDynamicDataPoint;
            this.showLoader = false;
            this.DynamicSelectionArray = this.getDynamicSelectionList;
            this.AddCriteria = "Add Criteria";
            this.AddCriteriaId = 0;
            this.dataPointDescription = "";
            this.dataPointHeading = "";
            this.showSelectedValue = [];
            this.isAddCriteria = true;
            // this.isExpanded = false;
            this.addEntityLevel = false;
            this.AddCriteriaKey = true;
            this.isSelectionValue = false;
            this.getselectedValue = [];
            this.selectedValueCount = 0;
            this.addCriteriaRemoveButton = false;
        
        },
        async resetDynamicUniverseSingleSelectionDataPoint(){
            this.showLoader = true;
            await this.fetchDynamicDataPoint(this.dynamicEntityTypeId);
            this.getDynamicSelectionList = this.getDynamicDataPoint;
            this.showLoader = false;
            this.DynamicSelectionArray = this.getDynamicSelectionList;
            this.AddCriteria = "Add Criteria";
            this.dynamicInputValue = "";
            this.selectedOperator = "";
            this.dataPointDescription = "";
            this.dataPointHeading = "";
            this.isAddCriteria = true;
            // this.isExpanded = false;
            this.addEntityLevel = false;
            this.AddCriteriaKey = true;
            this.isSelectionValue = false;
            this.getselectedValue = [];
            this.selectedValueCount = 0;
            this.addCriteriaRemoveButton = false;
        
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.create-universe {
    padding: 16px 16px 0 16px;
    position: relative;
}
.create-universe-heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    width: 177px;
    height: 36px;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}
.create-universe-selection-section {
    margin-bottom: 18px;
}
.create-universe-inputfield {
    padding: 0px;
    width: 361px;
    // height: 20px;
}
.create-universe-type {
    padding-left: $mds-space-4-x;
}

.create-universe-router {
    margin-left: $mds-space-half-x;
}
.create-universe-static {
    margin-top: $mds-space-1-x;
}
.create-universe-border {
    border-bottom: 2px solid #333333;
    height: 0px;
}
.middle-section {
    min-height:340px; 
    padding-bottom: 68px;
}
.bottom-section-border {
    margin-top: $mds-space-2-x;
    border-bottom: 1px solid #cccccc;
}
.bottom-section-button {
    margin-top: $mds-space-2-and-a-half-x;
}
.bottom-section-save-button {
    margin-left: $mds-space-2-x;
}
.staticTabs {
    margin-top: 20px;
}
.tabEntitiesRows {
    margin-top: 16px;
}
.search-input-component {
    background-color: $mds-color-neutral-95;
    border-radius: 15px;
    white-space: nowrap;
}
.levelRadioButton {
    margin-top: 8px;
    margin-bottom: 9px;
}
.middle-section-header-name {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
}
.additionalSearch-header-name {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    margin-top: 8px;
    margin-bottom: 8px;
}
.additionalSearch {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
}
.footerSection {
    margin-top: 10px;
    margin-bottom: 10px;
    position: absolute;
    bottom: 0;
    width: 97%;
}
.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
// ------------------

.dynamic-universe-heading {
    padding-left: 0px;
    padding-top: $mds-space-quarter-x;
    margin-bottom: 13px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
}
.dynamic-universe-criteria {
    margin-top: $mds-space-1-x;
    // height: 350px;
}
.dynamic-add-criteria-left-block {
    margin-top: $mds-space-2-x;
    height: 300px;
}
.dynamic-data-point-information {
    margin-top: $mds-space-1-x;
}
.dynamic-list-group {
    margin-top: 20px;
    // position: absolute;
    // width: 565px;
    height: 200px;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    overflow-y: auto;
}

.dynamic-input-field {
    height: 200px;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
}

.dynamic-section-title {
    font-size: 16px;
}

.dynamic-selected-value {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.dynamic-selection-of-value {
    margin-top: $mds-space-2-x;
}

.dynamic-selection-of-value-list {
    margin-bottom: $mds-space-1-x;
}

.dynamic-search-input {
    margin-top: $mds-space-1-x;
    background-color: $mds-color-neutral-95;
    border-radius: 15px;
    white-space: nowrap;
}

.dynamic-cancel-button {
    margin-left: $mds-space-1-x;
}

.dynamic-selected-list {
    padding: 0px;
    overflow-y: auto;
}
.dynamic-remove-selected-value {
    float: right;
    margin-top: $mds-space-half-x;
}
#createUniverse::v-deep .mds-list-group__link___VueMDS3Demo {
    padding-left: 0px;
    padding-right: 0px;
}
.dynamic-selection-of-value {
    margin-top: $mds-space-2-x;
}

.dynamic-selection-of-value-list {
    margin-bottom: $mds-space-1-x;
}

.dynamic-search-input {
    margin-top: $mds-space-1-x;
    background-color: $mds-color-neutral-95;
    border-radius: 15px;
    white-space: nowrap;
}

.dynamic-cancel-button {
    margin-left: $mds-space-1-x;
}

.dynamic-select-value-heading {
    left: 16px;
    top: 65px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    overflow-y: auto;
    height: 250px;
}
.dynamic-data-point-heading {
    margin-top: 48px;
    font-weight: bold;
    font-size: 16px;
}

#createUniverse::v-deep .mds-list-group__link___VueMDS3Demo {
    padding-left: 0px;
    padding-right: 16px;
}

#createUniverse::v-deep
    .mds-label___VueMDS3Demo
    .mds-label__text___VueMDS3Demo {
    margin-bottom: $mds-space-2-x;
}

.labelImportFile {
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
}

.uploadEntity {
    margin-top: 8px;
    padding: 10px;
    background: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 6px;
}

.notesText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 8px;
}

.uploadEntityMatched {
    margin-top: 2px;
}

.uploadEntityFile {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    text-align: start;
}

.uploadEntityCloseBtn {
    text-align: end;
}

.additionalSearchClearAll {
    text-align: end;
    margin-top: 5px;
}

.searchEntitiesField {
    margin-top: 3px;
}
</style>
